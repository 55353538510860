import * as React from 'react';
import { Input, Modal, InputNumber, DatePicker, LocaleProvider } from 'antd';
import Form, { FormComponentProps } from 'antd/lib/form';
import classNames from 'classnames';
import { Link, withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import ru_RU from 'antd/lib/locale-provider/ru_RU';
import 'moment/locale/ru';
//import { isFirefox, isMobile } from 'react-device-detect';

//import { DataAgreement } from 'app/components/Index/DataAgreement';
import style from './style.module.css';

import { DataAgreement } from 'app/components/Index/DataAgreement';
import { formatContract, formatFullName } from 'app/utils';
import {Button} from "../../components/Button";
import {fetchPaymentForm} from "../../api";
import {RequestForm} from "./RequestForm";

interface PaymentForm extends RouteComponentProps<void> {}
interface PaymentTypeForm extends FormComponentProps {}

const FormItem = Form.Item;

class PaymentForm extends React.Component<PaymentTypeForm & PaymentForm, any> {
  static defaultProps: Partial<PaymentTypeForm & PaymentForm> = {
  };
  constructor(props: PaymentTypeForm & PaymentForm) {
    super(props);
    this.state = {
      form: '',
      focusName: false,
      focusNumber: false,
      success: false,
      error: false,
      message: '',
      visible: false,
      click1: false,
      click2: true,
      click3: false,
      agree: true,
      nameLabel: false,
      numberLabel: false,
      emailLabel: false,
      loading: false,
      visibleDataAgreement: false,
      initRequestForm: false,
      requestFormParams: {
        action: '',
        fields: {}
      }
    };
  }

  handleSubmit = (e: any) => {
    e.preventDefault();
    this.props.form.validateFields((err: object, { name = '', contract = '', sum = '', date }) => {

      if (!err && this.state.click2) {
        this.setState({
          loading: true
        });
        // sum = sum + sum * 1.7 / 100;
        fetchPaymentForm({
          name,
          contract,
          sum,
          date
        }).then((response: any) => {
          let data = response;
          if (data.error) {
            this.setState({
              error: true,
              message: data.message
            });
          } else if (data.success) {
            this.setState({
              initRequestForm: true,
              requestFormParams: data
            })
          }
        })

      } else {
        console.log('ERROR::', err, name, contract, sum, date);
        if (!err) {
          /*if (!number || number.toString().length !== 10) this.changeBorderColor("number", "#e80000");*/
          return this.setState({
            error: true,
            message: 'Заполните обязательные поля'
          });
        }
      }
    });
  };

  wrapperHandlerModalFunc = (name: string, value: boolean) => {
    this.setState({
      ['visible' + name]: value
    });
  };

  handleNumberChange = (number: any) => {
    if (!number || number.toString().length !== 10) {
      this.setState({
        validateNumberStatus: 'error',
        error: false
      });
    } else {
      this.setState({
        validateNumberStatus: 'success',
        error: false
      });
    }
  };

  validateEmail = (email: string) => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  changeBorderColor(id: any, color: string) {
    let item: any = document.getElementById(id);
    item.style.borderColor = color;
  }

  toggleLabel = (name: string, toggler: boolean) => {

    if (this.state[name + 'Label'] !== toggler) {
      if (!(!toggler && this.props.form.getFieldValue(name))) {
        this.setState({
          [name + 'Label']: toggler
        });
      }
    }
  };

  handleErrorChange = () => {
    this.setState({
      error: false
    });
  };

  radioClick = (clickName: string) => {
    switch (clickName) {
      case '1':
        this.setState({
          ['click' + clickName]: !this.state['click' + clickName],
          click2: false,
          click3: false
        });
        break;
      case '2':
        this.setState({
          ['click' + clickName]: !this.state['click' + clickName],
          click1: false,
          click3: false
        });
        break;
      case '3':
        this.setState({
          ['click' + clickName]: !this.state['click' + clickName],
          click1: false,
          click2: false
        });
        break;
      default:
        break;
    }
  };

  radioClickAgree = () => {
    this.setState({
      agree: !this.state.agree
    });
  };

  handleCloseSuccessForm = () => {
    setTimeout(() => {
      this.props.form.resetFields();
      this.setState({
        focusName: false,
        focusNumber: false,
        success: false,
        error: false,
        message: '',
        visible: false,
        click1: true,
        click2: false,
        click3: false,
        nameLabel: false,
        numberLabel: false,
        emailLabel: false,
        loading: false,
        visibleDataAgreement: false,
        agree: true
      });
    }, 200);
  };

  handleCancel = (e: any) => {
    console.log(e);
    this.setState({
      visibleSendForm: false,
      visibleDataAgreement: false
    });
  };

  formatContractNumber = (value: any) => {
    console.log('CONTRACTOR NUMBER: ' + value);
    console.log(value.toString().replace(/[^0]/g, ''));
    let currentVal = value.toString()
        .replace(/[^0-9]/g, '')
        .replace(/^0+/g, '')
        .padStart(10,'0')
        .replace(/^(.{10}).*/g, '$1')
        .replace(/^0+$/g, '');
    console.log('CURRENT CONTRACT: ' + currentVal);
    return currentVal == ''
        ? ''
        : currentVal;
  };

  render() {
    const {
      form: { getFieldDecorator },
      form,
      history,
    } = this.props;

    const {
      agree,
      click1,
      click2,
      nameLabel,
      initRequestForm,
      visibleDataAgreement,
      requestFormParams: {
        action,
        fields
      }
    } = this.state;

    console.log('LOCATION', this.props);

    /*
    <div>
              <input
                type="checkbox"
                onClick={() => this.radioClick('1')}
                onChange={this.handleErrorChange}
                checked={this.state.click1}
              />
              <span>ООО МКК "Народная опора"</span>
            </div>
    <div>
              <input
                type="checkbox"
                onClick={() => this.radioClick('3')}
                onChange={this.handleErrorChange}
                checked={this.state.click3}
              />
              <span>ООО ЛОМБАРД "КРЕДИТКАР"</span>
      </div>

    * */

    let selectedRadioClassName = click1 ? style.firstRadioClick : style.secondRadioClick;
    return (
      <LocaleProvider locale={ru_RU}>
        <section>
        <Form className={style.sendForm}>
          <article>
            <FormItem>
              {getFieldDecorator('name', {
                rules: [
                  {
                    required: true,
                    message: <br />
                  },
                  {
                    validator: (rule: any, value: any, callback: any) => {
                      form.setFieldsValue({
                        name: formatFullName(value)
                      });
                      callback();
                    }
                  }
                ]
              })(<Input placeholder={!nameLabel ? 'ФИО заемщика *' : ' '} />)}
            </FormItem>
            <FormItem>
              {getFieldDecorator('contract', {
                rules: [
                  {
                    required: true,
                    message: <br />
                  },
                  {
                    validator: (rule: any, value: any, callback: any) => {
                      form.setFieldsValue({
                        contract: formatContract(value)
                      });
                      callback();
                    }
                  }
                ]
              })(<InputNumber formatter={this.formatContractNumber} placeholder={!nameLabel ? 'Номер договора *' : ' '} />)}
            </FormItem>

            <FormItem>
              {getFieldDecorator('date', {
                rules: [
                  {
                    required: true,
                    message: <br />
                  },
                  {
                    validator: (rule: any, value: any, callback: any) => {
                      console.log(value);
                      /*this.props.form.setFieldsValue({
                        date: formatDate(value)
                      });*/
                      callback();
                    }
                  }
                ]
              })(
                <DatePicker
                  format={'DD-MM-YYYY'}
                  dropdownClassName={style.dateDropdown}
                  placeholder={'Дата договора *'}
                />
              )}
            </FormItem>

            <FormItem>
              {getFieldDecorator('sum', {
                rules: [
                  {
                    required: true,
                    message: <br />
                  },
                  {
                    validator: (rule: any, value: any, callback: any) => {
                      console.log(value);
                      if (!isNaN(value)) {
                        if (+value > 50000) {
                          form.setFieldsValue({
                            sum: 50000
                          });
                        } else if (+value < 0) {
                          form.setFieldsValue({
                            sum: 0
                          });
                        }
                      } else {
                        if (value) {
                          form.setFieldsValue({
                            sum: value.replace(/[^0-9]/g, '')
                          });
                        }
                      }
                      callback();
                    }
                  }
                ]
              })(
                <InputNumber
                  placeholder={!nameLabel ? 'Сумма платежа *' : ' '}
                  max={50000}
                />
              )}
            </FormItem>
          </article>
          <article className={classNames(style.radioCircleWrapper, selectedRadioClassName)}>

            <div>
              <input
                type="checkbox"
                onClick={() => this.radioClick('2')}
                onChange={this.handleErrorChange}
                checked={click2}
              />
              <span>ООО МКК "КАПЕЛЛА"</span>
            </div>

          </article>
          <div className={style.radioSquareWrapper}>
            <input
              type="checkbox"
              onClick={() => this.radioClickAgree()}
              onChange={this.handleErrorChange}
              checked={agree}
            />
            <span>
              Я согласен (-на) с{' '}
              <span onClick={() => this.wrapperHandlerModalFunc('DataAgreement', true)}>
                политикой обработки персональных данных
              </span>
              .
            </span>
          </div>
          <Button
            className={style.submitButton + (!agree ? '' : '')}
            /*style={{
              'opacity':'0.5',
              'pointerEvents': 'none'
            }}*/
            onClick={this.handleSubmit}
            styleName={'old'}
          >
            ПРОДОЛЖИТЬ
          </Button>
          <div>
            <p>* - поля обязательны для заполнения</p>
            <div>
              <Link to={'/methods'}>
                Другие способы оплаты
              </Link>
              <a onClick={() => history.goBack()}>Назад</a>
            </div>
          </div>
          <Modal
            visible={visibleDataAgreement}
            onOk={this.handleCancel}
            onCancel={this.handleCancel}
            className={style.modalDataAgreement}
          >
            <DataAgreement />
          </Modal>
        </Form>
          {initRequestForm ? <RequestForm action={action} fields={fields} /> : ''}
        </section>
      </LocaleProvider>
    );
  }
}

export let WrappedCustomRouterPaymentForm = Form.create()(withRouter(PaymentForm));

