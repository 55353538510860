import * as React from 'react';
import classNames from 'classnames';

import style from './style.module.css';
//import button from 'assets/img/closeButton.png';
import button from 'assets/img/cross.svg'

export class Notificator extends React.Component<any, any> {
  static defaultProps: Partial<any> = {};

  constructor(props: any, context?: any) {
    super(props, context);
  }

  render() {
    return (
      <div id={'notificator'} className={classNames(style.notificator)} >
        <div onClick={(e: any) => {
          e.preventDefault();
          this.props.onAction();
        }}>{this.props.children}</div>
        <img src={button} onClick={this.props.onClose} alt="закрыть" />
      </div>
    );
  }
}
