import * as React from 'react';
import {Collapse} from 'antd';
import {Element} from 'react-scroll';

import style from './style.module.css';

const Panel = Collapse.Panel;

export interface Owner {
}

export class Owner extends React.Component<Owner> {
    static defaultProps: Partial<Owner> = {};

    render() {
        return (

            <section className={style.owner}>
                <Element name={'owner'}>
                    <h1>Займы предпринимателям</h1>
                    <p>
                        Малому бизнесу и представителям предпринимательства ИП под залог автомобиля. Автокредит
                        для бизнеса под залог автомобиля – отличный способ найти нужную сумму за короткий срок.
                    </p>
                    <Collapse className={style.collapse}>
                        <Panel header={<div>Подробнее</div>} key={'1'}>
                            <p>
                                Есть мнение, что банковские организации по негласному правилу отказывают индивидуальным
                                предпринимателям в кредите. Отчасти это правда, так как Вы сами выдаете себе справку о
                                доходах, а это настораживает банк. У банков уже есть негативный опыт.
                                <br/>
                                <br/>
                                Для некоторых банков ИП являются клиентами, кредитование которых несет повышенный
                                финансовый риск.
                                <br/>
                                <br/>
                                Если у Вас есть возможность подкрепить свои доходы управленческой отчетностью, Ваша
                                компания сможет показать положительную динамику за 12 месяцев на банковской выписке,
                                тогда вполне можно воспользоваться выгодными предложениями по автокредитованию у банков
                                представленными на рынке. Но что делать, если нет времени ждать, собирать справки.
                                Деньги нужны срочно. Деньги на разрыв в кассе, срочно оплатить товар поставщику,
                                перечислить предоплату за товар на складе, закупить новое оборудование?
                                <br/>
                                <br/>
                                Есть более быстрый способ, Вы можете обратиться к нам в компанию, и через час уехать с
                                наличными на руках. Вам нужно предоставить на проверку всего три документа и через час
                                Вы точно знаете какую сумму Вам предоставит наша компания. Вы можете дистанционно пройти
                                проверку, и только если Вас устроит сумма приехать на получение в офис. На сделку Вы так
                                же можете приехать в удобное для Вас время.
                            </p>
                        </Panel>
                    </Collapse>
                </Element>
            </section>
        );
    }
}
