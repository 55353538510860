import * as React from 'react';
import { InputNumber, Input, Select, Upload, Modal, message } from 'antd';
import Form, { FormComponentProps } from 'antd/lib/form';
//import { isFirefox, isMobile } from 'react-device-detect';

import { DataAgreement } from 'app/components/Index/DataAgreement';
import { Success } from 'app/components/Index/Success';
import style from './style.module.css';
import fileIcon from 'assets/img/fileIcon.png';
import * as currencyFormatter from 'currency-formatter';
import { formatEmail, formatName, formatNumber, parseNumber } from 'app/utils';
import axios from "axios";
import {Button} from "../Button";
import ym from "react-yandex-metrika";

interface SendTypeForm {
  [key: string]: any
  onCancel: any;
  showSum: boolean;
}

const FormItem = Form.Item;
const Option = Select.Option;

class SendForm extends React.Component<SendTypeForm & FormComponentProps, any> {
  static defaultProps: Partial<SendTypeForm & FormComponentProps> = {
    onCancel: () => null,
    showSum: false,
  };

  constructor(props: SendTypeForm & FormComponentProps) {
    super(props);
    this.state = {
      focusName: false,
      focusNumber: false,
      success: false,
      error: false,
      message: '',
      visible: false,
      click: false,
      nameLabel: false,
      numberLabel: false,
      emailLabel: false,
      loading: false,
      visibleDataAgreement: false,
      fileList: []
    };
  }

  handleSubmit = (e: any) => {
    e.preventDefault();
    this.props.form.validateFields((err: object, {
      name,
      number,
      email = '',
      region = ''
    }) => {
      let emailIsValid = email ? this.validateEmail('' + email) : true;
      let numberIsValid = number ? number.toString().length === 10 : false;

      console.log({
        name,
        number,
        email,
        region
      }, err);

      if (!err && this.state.click && numberIsValid && (emailIsValid || !email) && region) {

        this.setState({
          loading: true
        });
        const { fileList } = this.state;
        const formData = new FormData();
        let data = {
          name,
          phonenumber: number,
          email,
          region,
        };

        formData.append('data', JSON.stringify(data));
        if (fileList.length) {
          console.log('fileList:', this.state.fileList);

          fileList.forEach((file: any) => {
            console.log(file);
            formData.append('files[]', file);
          });

          console.log('formData.getAll files[]',formData.getAll('files[]'));
        }

        //this.handleShow();
        console.log('formData.getAll data',formData.get('data'));
        axios.post(window.location.origin + '/api/docs', formData).then((response) => {
          this.setState({
            loading: false
          });
          let respData = response.data;
          if (respData.error) {
            this.setState({
              error: true,
              message: respData.message || 'Что-то пошло не так. Попробуйте повторно отправить заявку.',
              loading: false
            })
          } else if (respData.success) {
            ym('reachGoal', 'max-form');
            console.log('success');
            this.handleShow();
          }
        }).catch(() => {
          this.setState({
            error: true,
            message: 'Что-то пошло не так. Попробуйте повторно отправить заявку.',
            loading: false
          })
        });
      } else if (!err && this.props.showSum) {
        this.handleShow();
      } else {
        console.log('ERROR::', err, name, number, email);
        if (!name) this.changeBorderColor('name', '#e80000');
        if (!emailIsValid) this.changeBorderColor('email', '#e80000');
        /*if (!err || !number) {
          /!*if (!number || number.toString().length !== 10) this.changeBorderColor("number", "#e80000");*!/

          return this.setState({
            error: true,
            message: 'Заполните обязательные поля.'
          });
        }*/

        if (!this.state.click) {
          this.setState({
            error: true,
            message: '* Подтвердите согласие на обработку данных.'
          });
        }
      }
    });
  };

  handleNumberChange = (number: any) => {
    if (!number || number.toString().length !== 10) {
      this.setState({
        validateNumberStatus: 'error',
        error: false
      });
    } else {
      this.setState({
        validateNumberStatus: 'success',
        error: false
      });
    }
  };

  validateEmail = (email: string) => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  changeBorderColor(id: any, color: string) {
    let item: any = document.getElementById(id);
    item.style.borderColor = color;
  }

  toggleLabel = (name: string, toggler: boolean) => {
    if (this.state[name + 'Label']) {
    }
    if (this.state[name + 'Label'] !== toggler) {
      if (!(!toggler && this.props.form.getFieldValue(name))) {
        this.setState({
          [name + 'Label']: toggler
        });
      }
    }
  };

  handleErrorChange = () => {
    this.setState({
      error: false
    });
  };

  radioClick = (clickName: string) => {
    this.setState({
      [clickName]: !this.state[clickName]
    });
  };

  handleFileChange = (info: any) => {
    let fileList = info.fileList;

    // 1. Limit the number of uploaded files
    // Only to show two recent uploaded files, and old ones will be replaced by the new
    console.log('fileList:',fileList);
    //fileList = fileList.slice(-2);


    // 2. Read from response and show file link
    fileList = fileList.map((file: any) => {
      if (file.response) {
        // Component will show file.url as link
        file.url = file.response.url;
      }
      return file;
    });

    // 3. Filter successfully uploaded files according to response from server
    fileList = fileList.filter((file: any) => {
      if (file.response) {
        return file.response.status === 'success';
      }
      return true;
    });

    this.setState({ fileList });
  };

  handleShow = () => {
    this.setState({
      visibleSuccess: true,
    });
  };

  handleCloseSuccessForm = () => {
    this.props.onCancel();
    setTimeout(() => {
      this.props.form.resetFields();
      this.setState({
        sumStatus: 'success',
        focusName: false,
        focusNumber: false,
        error: false,
        message: '',
        visible: false,
        click: false,
        nameLabel: false,
        numberLabel: false,
        emailLabel: false,
        loading: false,
        visibleDataAgreement: false
      });
    }, 200);
  };

  handleCancel = () => {
    this.setState({
      visibleDataAgreement: false,
      visibleSuccess: false,
    });
  };

  wrapperHandlerModalFunc = (name: string, value: boolean) => {
    this.setState({
      ['visible' + name]: value
    });
  };

  roundOffSum = (sum: number, step = 10000) => {
    if (sum < 10000) sum = 10000;
    console.log(sum, step, Math.round(sum / step) * 10000);
    return Math.round(sum / step) * 10000;
  };

  numberChange = (val: any) => {
    console.log(
      'Number change',
      currencyFormatter.format(val, {
        thousand: '',
        precision: 0,
        decimal: ''
      })
    );
    let numberVal: any = currencyFormatter.format(val, {
      thousand: '',
      precision: 0,
      decimal: ''
    });
    if (numberVal > 5000000) {
      this.setState({
        badSum: true
      });
      this.props.form.setFieldsValue({
        sum: 5000000
      });
    } else if (numberVal < 50000) {
      this.setState({
        badSum: false
      });
    } else {
      this.setState({
        badSum: false
      });
      this.changeBorderColor('sum', '#4bc0b0');
    }
    //this.handleChange()
  };

  render() {
    const {
      form: { getFieldDecorator }
    } = this.props;

    const props = {
      multiple: true,
      onRemove: (file: any) => {
        this.setState((state: any) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: (file: any) => {
        console.log('file', file);

        switch (file.type) {
          case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          case 'application/msword':
          case 'application/pdf':
          case 'image/jpeg':
          case 'image/png':
          case 'image/bmp':
            this.setState((state: any) => ({
              fileList: [...state.fileList, file],
            }));
            break;
          default:
            message.error('Вы можете загружать документы в формате doc, docx, pdf.' + file.type);
            break
        }

        return false;
      },
    };

    let itemClassName = ''; //style.nameItem;
    let numberClassName = ''; //style.numberItem;
    /* if (isFirefox || isMobile) {
      itemClassName = style.mozNameItem;
      numberClassName = style.mozNumberItem;
    }*/

    return (
      <Form className={style.sendForm}>
        {this.props.showSum ? (
          <FormItem validateStatus={this.state.sumStatus}>
            {getFieldDecorator('sum', {
              rules: [
                {
                  required: true,
                  message: ' '
                }
              ]
            })(
              <Input
                placeholder={'Сумма займа'}
                onChange={this.handleErrorChange}
                onBlur={() => {
                  let sum = +this.props.form.getFieldValue('sum');
                  console.log('sum', sum);
                  if (typeof sum === 'number' && Math.floor(sum) === sum) {
                    console.log('this is number');
                    this.setState({
                      sumStatus: 'success'
                    });
                  } else {
                    this.setState({
                      sumStatus: 'error'
                    });
                  }
                }}
              />
            )}
          </FormItem>
        ) : (
          <article>
            <FormItem
              className={!this.state.focusName ? itemClassName : ''}
              label={this.state.nameLabel ? 'Ваше имя *' : ' '}
            >
              {getFieldDecorator('name', {
                rules: [
                  {
                    required: true,
                    message: <br />
                  },
                  {
                    validator: (rule: any, value = '', callback: any) => {
                      this.props.form.setFieldsValue({
                        name: formatName(value)
                      });
                      callback();
                    }
                  }
                ]
              })(
                <Input
                  placeholder={!this.state.nameLabel ? 'Ваше имя *' : ' '}
                  onChange={this.handleErrorChange}
                  onFocus={() => {
                    this.toggleLabel('name', true);
                    this.setState({
                      focusName: true
                    });
                  }}
                  onBlur={() => {
                    this.toggleLabel('name', false);
                    console.log(this.props.form.getFieldValue('name'));
                    if (!this.props.form.getFieldValue('name')) {
                      this.setState({
                        focusName: false
                      });
                    }
                  }}
                />
              )}
            </FormItem>
            <FormItem
              className={!this.state.focusNumber ? numberClassName : ''}
              validateStatus={this.state.validateNumberStatus}
              label={this.state.numberLabel ? 'Номер мобильного телефона *' : ' '}
            >
              {getFieldDecorator('number', {
                rules: [
                  {
                    required: true,
                    message: <br />
                  }
                ]
              })(
                <InputNumber
                  placeholder={!this.state.numberLabel ? 'Номер мобильного телефона *' : ' '}
                  formatter={formatNumber}
                  parser={parseNumber}
                  onChange={this.handleNumberChange}
                  onFocus={() => {
                    this.toggleLabel('number', true);
                    if (!this.props.form.getFieldValue('number')) {
                      this.props.form.setFieldsValue({
                        number: ' '
                      });
                    }
                    this.setState({
                      focusNumber: true
                    });
                  }}
                  onBlur={() => {
                    this.toggleLabel('number', false);
                    console.log(this.props.form.getFieldValue('number'));
                    if (this.props.form.getFieldValue('number') === ' ') {
                      this.props.form.setFieldsValue({
                        number: ''
                      });
                      this.setState({
                        focusNumber: false
                      });
                    }
                    if (!this.props.form.getFieldValue('number')) {
                      this.setState({
                        focusNumber: false
                      });
                    }
                  }}
                />
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator('region', {
                rules: [
                  {
                    required: true,
                    message: <br />
                  }
                ]
              })(
                <Select
                  style={{
                    marginBottom: '20px'
                  }}
                  autoFocus
                  dropdownStyle={{
                    paddingTop: '15px',
                    paddingBottom: '15px'
                  }}
                  placeholder={'Город *'}
                  onChange={this.handleErrorChange}
                  dropdownClassName={style.selectedList}
                >
                  <Option className={style.unSelectedItem} value="Санкт-Петербург и ЛО">
                    Санкт-Петербург и область
                  </Option>
                  {/*<Option className={style.unSelectedItem} value="Москва">Москва</Option>*/}
                </Select>
              )}
            </FormItem>
            <FormItem label={this.state.emailLabel ? 'Электронная почта' : ' '}>
              {getFieldDecorator('email', {
                rules: [
                  {
                    validator: (rule: any, value = '', callback: any) => {
                      console.log('formatEmail(value)', formatEmail(value))
                      if (value) {
                        this.props.form.setFieldsValue({
                          email: formatEmail(value) || ''
                        });
                      }
                      callback();
                    }
                  }
                ]
              })(
                <Input
                  placeholder={!this.state.emailLabel ? 'Электронная почта' : ' '}
                  onChange={this.handleErrorChange}
                  onFocus={() => this.toggleLabel('email', true)}
                  onBlur={() => this.toggleLabel('email', false)}
                />
              )}
            </FormItem>
          </article>
        )}
        <Upload className={style.fileAreaWrapper} {...props} fileList={this.state.fileList}>
          <p>Вы можете добавить документы на проверку (паспорт РФ, СТС, ПТС)</p>
          <div className={style.addFileButton} />
        </Upload>
        <ul className={style.fileList}>
          <li>
            <a
              href={'/files/Общие%20условия%20договора%20потребительского%20микрозайма.pdf'}
              target="_blank"
              rel="noopener noreferrer">
              <img src={fileIcon} alt="" />
              <span>Общие условия договоров</span>
            </a>
          </li>
          <li>
            <a
              href={'/files/Информация%20об%20условиях%20предоставления.pdf'}
              target="_blank"
              rel="noopener noreferrer">
              <img src={fileIcon} alt="" />
              <span>Информация об условиях</span>
            </a>
          </li>
        </ul>
        <div className={style.radioWrapper}>
          <input
            onClick={() => this.radioClick('click')}
            onChange={this.handleErrorChange}
            type={'checkbox'}
            checked={this.state.click}
          />
          <span>
            Согласие на{' '}
            <span onClick={() => this.wrapperHandlerModalFunc('DataAgreement', true)}>
              обработку данных
            </span>
          </span>
        </div>
        {this.state.error ? <span className={style.error}>{this.state.message}</span> : ''}
        <Button
            className={style.submitButton}
            onClick={this.handleSubmit}
            styleName={'old'}
        >
          ОТПРАВИТЬ
        </Button>
        <p>* - поля обязательны для заполнения</p>
        <Modal
          visible={this.state.visibleDataAgreement}
          onOk={this.handleCancel}
          onCancel={this.handleCancel}
          className={style.modalDataAgreement}
        >
          <DataAgreement />
        </Modal>
        <Modal
          visible={this.state.visibleSuccess}
          onOk={() => {
            this.handleCancel();
            this.props.onCancel();
          }}
          onCancel={() => {
            this.handleCancel();
            this.props.onCancel();
          }}
          className={style.modalSuccessForm}
          destroyOnClose={true}
        >
          <Success closeForm={() => {
            this.handleCancel();
            this.props.onCancel();
          }} />
        </Modal>
      </Form>
    );
  }
}

export const WrappedCustomSendForm = Form.create()(SendForm);
