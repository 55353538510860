import * as React from 'react';
import { Modal } from 'antd';

import style from './style.module.css';

import img1 from 'assets/icons/index/purse.svg';
import img2 from 'assets/icons/index/sandglass.svg';
import img3 from 'assets/icons/index/calendar.svg';
import img4 from 'assets/icons/index/paperplane.svg';
import img5 from 'assets/icons/index/clock.svg';
import img6 from 'assets/icons/index/like.svg';
import { Button } from 'app/components/Button';

export interface Features {
}

export class Features extends React.Component<Features, any> {
  static defaultProps: Partial<Features> = {};

  constructor(props: Features, context?: any) {
    super(props, context);
    this.state = {
      visibleInfo: false
    };
  }

  showModal = () => {
    this.setState({
      visibleInfo: true
    });
  };

  hideModal = () => {
    this.setState({
      visibleInfo: false
    });
  };

  features = [
    {
      image: img1,
      text: <div>Выдача займа наличными</div>
    },
    {
      image: img2,
      text: <div>Работаем прозрачно, в соответствии с законом РФ</div>
    },
    {
      image: img3,
      text: <div>Рассматриваем заявки с любой кредитной историей</div>
    },
    {
      image: img4,
      text: <div>Без поручителей Без справок</div>
    },
    {
      image: img5,
      text: <div>Выдаём деньги 7 дней в неделю</div>
    },
    {
      image: img6,
      text: (
        <div onClick={this.showModal}>
          Досрочное погашение на любом сроке<span className={style.red}>*</span>
        </div>
      )
    }
  ];

  render() {
    return (
      <section className={style.features}>
        {this.features.map(({ image, text }, i) => (
          <div key={i}>
            <img src={image} alt="" />
            {text}
          </div>
        ))}
        <Modal
          visible={this.state.visibleInfo}
          onOk={this.hideModal}
          onCancel={this.hideModal}
          className={style.modalInfo}
        >
          <div className={style.info}>
            <p>
              Срок от 30 дней до 12 месяцев возможен при пролонгации Договора. Пролонгация
              (продление) Договора микрозайма означает изменение срока действия договора
              потребительского займа и продление его на дополнительный срок. Погашение суммы займа и
              уплаты процентов на сумму займа в данном случае осуществляется на основании Изменений
              к Договору/Дополнительного соглашения/иных документов.
            </p>
            <div>
              <Button onClick={this.hideModal}>ОК</Button>
            </div>
          </div>
        </Modal>
      </section>
    );
  }
}
