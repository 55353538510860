import * as React from 'react';
import { Collapse, Icon } from 'antd';
import classnames from 'classnames';

import style from './style.module.css';
import car1 from 'assets/img/car1.png';
import car2 from 'assets/img/car2.png';
import car3 from 'assets/img/car3.png';
import act from 'assets/img/act.png';
import up from 'assets/img/up.png';

const currencyFormatter = require('currency-formatter');
const Panel = Collapse.Panel;

export interface Auctions {}


export class Auctions extends React.Component<Auctions, any> {
  static defaultProps: Partial<Auctions> = {};

  state = {
    moveArrow: false
  };

  moveArrow = () => {
    this.setState({
      moveArrow: !this.state.moveArrow
    });
  };

  renderMenu = (
    array: [
      {
        label: string;
        text: string;
      }
    ]
  ) => {
    return (
      <ul className={style.menu}>
        {array.map(({ label = '', text = '' }, i) => (
          <li key={i}>
            <b>{label}</b>: {text}
          </li>
        ))}
        <li className={'disabled'}>
          <img src={act} alt="" />
          <span>Акт осмотра</span>
        </li>
      </ul>
    );
  };

  auctions: any[] = [
    {
      img: car1,
      imgs: [car1, car2, car3],
      number: '15472',
      name: 'Hyundai Elantra V, 2002',
      time: '25 марта 2019',
      desc: [
        {
          label: 'Двигатель',
          text: '1.6 л / 132 л.с. / Бензин'
        },
        {
          label: 'Пробег',
          text: '72 000 км'
        },
        {
          label: 'Коробка',
          text: 'Автоматическая'
        },
        {
          label: 'Привод',
          text: 'Передний'
        }
      ],
      cost: 70000
    },
    {
      img: car1,
      imgs: [car1, car2, car3],
      number: '15472',
      name: 'Hyundai Elantra V, 2002',
      time: '25 марта 2019',
      desc: [
        {
          label: 'Двигатель',
          text: '1.6 л / 132 л.с. / Бензин'
        },
        {
          label: 'Пробег',
          text: '72 000 км'
        },
        {
          label: 'Коробка',
          text: 'Автоматическая'
        },
        {
          label: 'Привод',
          text: 'Передний'
        }
      ],
      cost: 70000
    },
    {
      img: car1,
      imgs: [car1, car2, car3],
      number: '15472',
      name: 'Hyundai Elantra V, 2002',
      time: '25 марта 2019',
      desc: [
        {
          label: 'Двигатель',
          text: '1.6 л / 132 л.с. / Бензин'
        },
        {
          label: 'Пробег',
          text: '72 000 км'
        },
        {
          label: 'Коробка',
          text: 'Автоматическая'
        },
        {
          label: 'Привод',
          text: 'Передний'
        }
      ],
      cost: 70000
    },
    {
      img: car1,
      imgs: [car1, car2, car3],
      number: '15472',
      name: 'Hyundai Elantra V, 2002',
      time: '25 марта 2019',
      desc: [
        {
          label: 'Двигатель',
          text: '1.6 л / 132 л.с. / Бензин'
        },
        {
          label: 'Пробег',
          text: '72 000 км'
        },
        {
          label: 'Коробка',
          text: 'Автоматическая'
        },
        {
          label: 'Привод',
          text: 'Передний'
        }
      ],
      cost: 70000
    },
    {
      img: car1,
      imgs: [car1, car2, car3],
      number: '15472',
      name: 'Hyundai Elantra V, 2002',
      time: '25 марта 2019',
      closed: true,
      desc: [
        {
          label: 'Двигатель',
          text: '1.6 л / 132 л.с. / Бензин'
        },
        {
          label: 'Пробег',
          text: '72 000 км'
        },
        {
          label: 'Коробка',
          text: 'Автоматическая'
        },
        {
          label: 'Привод',
          text: 'Передний'
        }
      ],
      cost: 70000
    },
    {
      img: car1,
      imgs: [car1, car2, car3],
      number: '15472',
      name: 'Hyundai Elantra V, 2002',
      time: '25 марта 2019',
      closed: true,
      desc: [
        {
          label: 'Двигатель',
          text: '1.6 л / 132 л.с. / Бензин'
        },
        {
          label: 'Пробег',
          text: '72 000 км'
        },
        {
          label: 'Коробка',
          text: 'Автоматическая'
        },
        {
          label: 'Привод',
          text: 'Передний'
        }
      ],
      cost: 70000
    }
  ];

  renderAuctions = (auctions : any[]) => {
    const CollapseHeader = (props: any) => (
        <h3 className={style.aucHeader}>
          <Icon className={this.state.moveArrow ? style.arrowMoved : ''} type="caret-right" />
          <span>{props.name}</span>
        </h3>
    );
    return auctions.map(({ img, imgs, name, number, time, desc, cost, closed }, i) => (
        <article
            key={i}
            className={classnames(style.mobileWrapper, closed ? style.closedMobileWrapper : '')}
        >
          <div>Аукцион №{number}</div>
          <article className={style.auction}>
              <span>
                Аукцион
                <br />№{number}
              </span>
            <div>
              <img src={img} alt="" />
            </div>
            <div>
              {closed ? (
                  <p className={'red'}>Завершен</p>
              ) : (
                  <p>
                    Дата окончания: <br />
                    {time}
                  </p>
              )}
              {closed ? (
                  <div className={style.closedName}>{name}</div>
              ) : (
                  <Collapse onChange={this.moveArrow}>
                    <Panel
                        key={'0'}
                        showArrow={false}
                        header={<CollapseHeader name={name} />}
                        className={style.menuPanel}
                    >
                      {this.renderMenu(desc)}
                      <div>
                        <div className={style.gradientImages} />
                        {imgs.map((item: any, i: number) => (
                            <img key={i} src={item} alt="" />
                        ))}
                      </div>
                    </Panel>
                  </Collapse>
              )}
            </div>

            <div>
              {closed ? (
                  <img src={up} alt="" />
              ) : (
                  currencyFormatter.format(cost, {
                    code: 'RUB',
                    precision: 0
                  })
              )}
            </div>
          </article>
        </article>
    ))
  };

  render() {
    /*{this.renderAuctions(this.auctions)}*/
    return (
      <div className={style.auctions}>
        <div className={style.noMatch}>В настоящее время нет действующих аукционов</div>
      </div>
    );
  }
}
