import * as React from 'react';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import { RouteComponentProps, withRouter} from 'react-router';

import insta from 'assets/icons/index/insta.png';
import vk from 'assets/icons/index/vk.png';
import mir from 'assets/img/mir.png';
import logo from 'assets/img/creditcar_logo.png';

import style from './style.module.css';
import {scroller as scroll} from "react-scroll/modules";
interface IFooter extends RouteComponentProps {
  [key: string]: any;
}

class Footer extends React.Component<IFooter, any, any> {
  links: any[] = [
    [
      {
        title: 'О компании',
        link: '/about'
      },
      // {
      //   title: 'Документы',
      //   link: '/docs'
      // },
      /*{
        title: 'Инвестиции',
        disabled: true,
        link: '/'
      },
      {
        title: 'Франшиза',
        disabled: true,
        link: '/'
      },*/
      {
        title: 'Контакты',
        link: '/contacts'
      }
    ],
    [
      {
        title: 'Займы',
        link: '/services'
      },
      {
        title: 'Способы оплаты',
        link: '/methods'
      },
      {
        title: 'Выслать документы на проверку',
        clickFunc: () => {
          this.props.showForm('SendForm', false);
        },
        link: '#'
      },
      {
        title: 'Аукционы',
        link: '/auctions'
      },
      {
        title: 'Вопросы и ответы',
        link: '/questions'
      },
      /*{
        title: 'Проверить карту',
        link: '/api/tinkoff/verify'
      },*/
      /*{
        title: 'Примеры выданных займов',
        disabled: true,
        link: '/samples'
      }*/
    ],
    [
      {
        title: this.props.match.path !== '/' ? <Link to={'/'}>Займы предпринимателям</Link> : 'Займы предпринимателям',
        link: '#',
        clickFunc: () => {
          this.scrollToElement('owner')
        },
      }
    ],
    [
      {
        title: 'Карта сайта',
        link: '/sitemap'
      }
    ]
  ];

  scrollToElement = (name: string, offset = true) => {
    let headerNode = document.getElementById('header') || { offsetHeight: 0 };
    let offsetHeight = offset ? -headerNode.offsetHeight || -50 : 0;

    console.log('offset', offsetHeight);
    setTimeout(() => {
      scroll.scrollTo(name, {
        offset: offsetHeight,
        spy: true,
        smooth:true,
        duration:1000
      })
    }, 500)
  };

  render() {
    return (
      <footer className={classNames(style.footer)}>
        <div>
          <section>
            <h3>Компания</h3>
            <ul className={style.menu}>
              {this.links[0].map((data: any, i: any) => {
                let { title = '', link = '/', disabled = false } = data;
                return (
                <li key={i} className={disabled ? 'disabled' : ''}>
                  {this.props.renderLink(link, title)}
                </li>)
              })}
            </ul>
          </section>
          <section>
            <h3>Частным клиентам</h3>
            <ul className={style.menu}>
              {this.links[1].map((data: any, i: any) => {
                  let { title = '', link = '/', disabled = false, clickFunc = false } = data;
                  return (
                  <li key={i} className={disabled ? 'disabled' : ''}>
                    {this.props.renderLink(link, title, clickFunc)}
                  </li>
                )}
              )}
            </ul>
            <ul className={style.menu}>              
              <li><a href="/api/tinkoff/verify">Проверить карту</a></li>
            </ul>
          </section>
          <section>
            <h3>Предпринимателям</h3>
            <ul className={style.menu}>
              {this.links[2].map((data: any, i: any) => {
                let { title = '', link = '/', disabled = false, clickFunc = false } = data;
                return (
                    <li key={i} className={disabled ? 'disabled' : ''}>
                      {this.props.renderLink(link, title, clickFunc)}
                    </li>
                )}
              )}
            </ul>
          </section>
          <section>
            <ul className={style.menu}>
              {this.links[3].map((data: any, i: any) => {
                let { title = '', link = '/', disabled = false, clickFunc = false } = data;
                return (
                    <li key={i} className={disabled ? 'disabled' : ''}>
                      {this.props.renderLink(link, title, clickFunc)}
                    </li>
                )}
              )}
            </ul>
          </section>
          {/*<div>
            <img src={vk} alt="vk" className={'disabled'} />
            <img src={insta} alt="instagram" className={'disabled'} />
          </div>*/}
        </div>
        {/*<section>
          <div>
            <img src={mir} alt="МИР" />
            <img src={logo} alt="CreditCar" />
          </div>
          <h3>Общество с ограниченной ответственностью Микрокредитная компания «КАПЕЛЛА».</h3>
          <p>
            ООО МКК «Капелла». Регистрационный номер записи в государственном реестре
            микрофинансовых организаций
            <br />
            № 1903504009230,
            <br />
            ОГРН 1162468123102.
          </p>
        </section>*/}
      </footer>
    );
  }
}

export const WrappedCustomFooter = withRouter(Footer);
