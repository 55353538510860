import * as React from 'react';

import addressIcon1 from 'assets/img/address_blue_icon.png';
// import addressIcon2 from 'assets/img/address_red_icon.png';
/*import pointer from 'assets/img/pointer2.png';
import companyPoint from 'assets/img/companyPoint.png';*/

import style from './style.module.css';
import metro_bot from "../../../assets/img/mosmetro_sign_bot.svg";
import metro_otr from "../../../assets/img/mosmetro_sign_otr.svg";


export interface Contacts  {
  [key: string]: any
  visibleSendForm: any;
}


export class Contacts extends React.Component<Contacts, any> {
  static defaultProps: Partial<Contacts> = {
    visibleSendForm: false
  };

  constructor(props: any) {
    super(props);
    this.state = {
        map: '',
        map2: '',
        slider: '',
    };
  }

  componentDidMount() {
    import("app/components/Map")
        .then(({ Map }) => {
            console.log('load Map');
            this.setState({
                map: Map
            })
        })
        .catch(err => {
            console.log(err)
        // Handle failure
        });
      import("app/components/Map2")
          .then(({ Map2 }) => {
              console.log('load Map2');
              this.setState({
                  map2: Map2
              })
          })
          .catch(err => {
              console.log(err)
              // Handle failure
          });
      import("app/components/Slider")
        .then(({ SliderComponent }) => {
          console.log('load Slider');
          this.setState({
            slider: SliderComponent
          })
        })
        .catch(err => {
          console.log(err)
          // Handle failure
        });
  }

  render() {
      let isMoscow = false;//window.location.hash == '#mos';
      let Map = isMoscow
        ? (this.state.map2 || (() => <div/>))
        : (this.state.map || (() => <div/>));
      let spbContent = (
          <section>
          <h1>Санкт-Петербург</h1>
      <Map/>
      <div className={style.address}>
          <div>
              <p>Пр. Коломяжский, д. 18</p>
              <p>
                  <img src={addressIcon1} alt="" />
                  Пионерская — 800 м
              </p>
          </div>
          <article>
              <div>
                  <p>График работы:</p>
                  <p>Пн-Пт, с 10.00 до 19.00</p>
              </div>
              <a href="tel:+78006009580">тел. 8-800-600-95-80</a>
          </article>
      </div>
          </section>
      );
      let mscContent = (
          <section>
              <h1>Москва</h1>
              <Map/>
              <div className={style.address}>
                  <div>
                      <p>Берёзовая аллея, 5Ас5</p>
                      <p>
                          <img src={metro_bot} alt="Ботанический сад" /> Ботанический сад — 1300 м<br />
                          <img src={metro_otr} alt="Отрадное" />Отрадное — 2300 м
                      </p>
                  </div>
                  <article>
                      <div>
                          <p>График работы:</p>
                          <p>Пн-Пт, с 10.00 до 19.00</p>
                      </div>
                      <a href="tel:+78006009580">тел. 8-800-600-95-80</a>
                  </article>
              </div>

          </section>

      )
    let Slider = this.state.slider || (() => <div/>);
    return (
        <div className={style.contacts}>
          {isMoscow ? mscContent : spbContent}
            <Slider />
        </div>
    );
  }
}
