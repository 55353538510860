import * as React from 'react';
import { Link } from 'react-router-dom';
import { RouteComponentProps, withRouter} from 'react-router';
import { Tree } from 'antd';

import style from './style.module.css';
import {scroller as scroll} from "react-scroll/modules";

const TreeNode = Tree.TreeNode;

export interface Sitemap extends RouteComponentProps<void> {
    visibleSendForm: any;
    showForm: any;
}

export class Sitemap extends React.Component<Sitemap, any> {
  static defaultProps: Partial<Sitemap> = {
    visibleSendForm: false
  };

  constructor(props: Sitemap, context?: any) {
    super(props, context);
    this.state = {};
    this.generateTitle = this.generateTitle.bind(this);
  }

  links: any[] = [
    {
      title: 'Главная',
      link: '/',
      children: [
        {
          title: 'Компания',
          children: [
            /*{
              title: 'Личный кабинет',
              disabled: true,
              link: '/login',
            },*/
            {
              title: 'Онлайн оплата',
              link: '/payment'
            },
            {
              title: 'О компании',
              link: '/about'
            },
            // {
            //   title: 'Документы',
            //   link: '/docs'
            // },
            /*{
              title: 'Инвестиции',
              disabled: true,
              link: '/'
            },*/
            /*{
              title: 'Франшиза',
              disabled: true,
              link: '/'
            },*/
            {
              title: 'Контакты',
              link: '/contacts'
            }
          ]
        },
        {
          title: 'Частным клиентам',
          children: [
            {
              title: 'Займы',
              link: '/services'
            },
            /*{
              title: 'Способы оплаты',
              disabled: true,
              link: '/methods'
            },*/
            {
              title: 'Выслать документы на проверку',
              link: '#',
              clickFunc: () => {
                if (this.props.showForm) {
                  this.props.showForm('SendForm', false);
                }
              }
            },
            {
              title: 'Аукционы',
              link: '/auctions'
            },
            {
              title: 'Вопросы и ответы',
              link: '/questions'
            },
            /*{
              title: 'Примеры выданных займов',
              disabled: true,
              link: '/samples'
            }*/
          ]
        },
        {
          title: 'Предпринимателям',
          disabled: true,
          children: [
            {
              title: <Link to={'/'}>Займы предпринимателям</Link>,
              link: '#',
              clickFunc: () => {
                this.scrollToElement('owner')
              }
            }
          ]
        }
      ]
    }
  ];

  scrollToElement = (name: string, offset = true) => {
    let headerNode = document.getElementById('header') || { offsetHeight: 0 };
    let offsetHeight = offset ? -headerNode.offsetHeight || -50 : 0;

    console.log('offset', offsetHeight);
    setTimeout(() => {
      scroll.scrollTo(name, {
        offset: offsetHeight,
        spy: true,
        smooth:true,
        duration:1000
      })
    }, 500)
  };

  generateTitle() {}

  handleClickUrl(link: any) {
    window.location = link;
  }

  render() {
    const loop = (data: any) =>
      data.map((item: any, i: number) => {
        let disabledClass: string = item.disabled ? ' disabled' : '';
        let title: any = item.link ? (
          item.anotherLink ? (
            <a
              href={process.env.REACT_APP_LK_URL + item.link}
              onClick={() => this.handleClickUrl(process.env.REACT_APP_LK_URL + item.link)}
            >
              {item.title}
            </a>
          ) : item.clickFunc ? (
            <span onClick={item.clickFunc}>{item.title}</span>
          ) : (
            <Link to={item.link}>{item.title}</Link>
          )
        ) : (
          <div>{item.title}</div>
        );
        if (item.children && item.children.length) {
          return (
            <TreeNode key={item.title} title={title}>
              {loop(item.children)}
            </TreeNode>
          );
        }
        return <TreeNode key={item.title} title={title} className={disabledClass} />;
      });

    const expandedKeys = ['Компания', 'Частным клиентам', 'Предпринимателям'];

    return (
      <div className={style.sitemap}>
        <Tree className="draggable-tree" defaultExpandedKeys={expandedKeys}>
          {loop(this.links)}
        </Tree>
      </div>
    );
  }
}
