import * as React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import { WrappedCustomRouterPaymentForm as PaymentForm } from './PaymentForm';

import mastercard from 'assets/img/mastercard.png';
import maestro from 'assets/img/maestro.png';
import visa from 'assets/img/visa.png';
import mir from 'assets/img/mirpay.png';
import style from './style.module.css';

export interface Pay {}

export class Pay extends React.Component<Pay, any> {
  static defaultProps: Partial<Pay> = {};

  constructor(props: Pay, context?: any) {
    super(props, context);
    this.state = {
      autoPay: true
    };
  }

  toggleData = (val: boolean) => {
    this.setState({
      autoPay: val
    });
  };

  text1 = (
    <div className={style.text}>
      <p>
        Обычный платеж банковской картой. Можно оплатить любую сумму в любой момент времени. На
        такой платеж начисляется <b>комиссия 1,7%</b>.
      </p>
    </div>
  );

  text2 = (
    <div className={style.text}>
      <p>
        Автоматическую оплата ваших платежей. Удобный и бесплатный способ обслуживать свой займ без
        просрочек, улучшая свою кредитную историю. За такой платеж <b>комиссия составляет 0%</b>.
      </p>
      <p>
        При его подлючении еженедельный платеж будет списываться с вашей карты автоматически в
        строгом соответствии с вашим графиком платежей. Нужно лишь позаботиться о том, чтобы на
        вашей карте было достаточно денежных средств в день проведения платежа.
      </p>
      <p>
        Вы можете в любой момент отказаться от автоматического платежа перейдя по этой{' '}
        <b>
          <a href="#" className={'disabled'}>
            ссылке
          </a>
        </b>
        .
      </p>
    </div>
  );

  render() {
    let paymentClass = style.payment;
    let text = this.text1;

    if (this.state.autoPay) {
      //paymentClass = classnames(style.payment, style.autoPayment);
      //text = this.text2;
    }

    return (
      <div className={style.pay}>
        <section>
          <h1>Оплата займа банковской картой</h1>
          <div>
            <img src={mastercard} alt="Mastercard" />
            <img src={maestro} alt="Maestro" />
            <img src={visa} alt="VISA" />
            <img src={mir} alt="МИР" />
          </div>
        </section>
        <section className={paymentClass}>
          <article>
            <div onClick={() => this.toggleData(false)}>Единоразовый платеж</div>
            <div className={'disabled'} onClick={() => this.toggleData(true)}>Автоматический (рекуррентный) платеж</div>
          </article>
          {text}
          <article>
            <PaymentForm />
          </article>
          <article>
            <Link to={'/methods'}>Другие способы оплаты</Link>
          </article>
        </section>
      </div>
    );
  }
}
