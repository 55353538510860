import * as React from 'react';

import {Button} from "../../components/Button";
import {Link} from 'react-router-dom'
import style from './style.module.css';

export interface VerifyResult {
}

export class VerifyResult extends React.Component<VerifyResult, any> {
  static defaultProps: Partial<VerifyResult> = {};

  constructor(props: VerifyResult, context?: any) {
    super(props, context);
    this.state = {};
  }

  render() {
    return (
      <div className={style.verify}>
        <h1>Ваша карта успешно прошла верификацию!</h1>
        <Link to='/' className={style.returnLink}>Вернуться на главную</Link>
      </div>
    );
  }
}
