import * as React from 'react';
import style from './style.module.css';
import {Button} from "../../Button";

export class Success extends React.PureComponent<any> {
  render() {
    return (
      <section className={style.success}>
        <h2>СПАСИБО ЗА ОБРАЩЕНИЕ В НАШУ КОМПАНИЮ!</h2>
        <h4>Ваша заявка успешно принята!</h4>
        <p>
          В ближайшее время с Вами свяжется наш сотрудник для уточнения условий предоставления займа.
        </p>
        <Button
            className={style.submitButton}
            onClick={this.props.closeForm}
            styleName={'old'}
        >
          ОК
        </Button>
      </section>
    );
  }
}
