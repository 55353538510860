import { App as MainApp } from 'app/containers/App';
import { Contacts } from 'app/containers/Contacts';
import { About } from 'app/containers/About';
import { Services } from 'app/containers/Services';
// import { Docs } from 'app/containers/Docs';
import { Info } from 'app/containers/Info';
import { Samples } from 'app/containers/Samples';
import { Auctions } from 'app/containers/Auctions';
import { Questions } from 'app/containers/QA';
import { Pay } from 'app/containers/Pay';
import { Methods } from 'app/containers/Methods';
import { ActionFriend } from 'app/containers/ActionFriend';
// import { Loans } from 'app/containers/Loans';
import { Sitemap } from 'app/containers/Sitemap';
import { Success } from 'app/components/Success';
import { VerifyResult } from 'app/containers/VerifyResult';
import { VerifyFail } from 'app/containers/VerifyFail';
import { CardsList } from 'app/containers/CardsList';
import { CardsPay } from 'app/containers/CardsPay';

export const mainRoutes: {
  route: string;
  Component: any;
  subheader: any;
  meta: any;
}[] = [
  {
    route: '/',
    Component: MainApp,
    subheader: {},
    meta: {
      title: 'CreditCar | Займ под залог ПТС в СПб',
      description: 'CreditCar Займ под залог ПТС в СПб, оказываем услуги автоломбарда, но не забираем машину, пользуйся авто как раньше. Без подтверждения дохода и поручителей. Под залог авто нужно всего три документа: паспорт РФ, СТС, ПТС. Не ставим отметок в ПТС. До 1 000 000 рублей. Ставка от 1,5 % в месяц.',
      canonical: 'https://credit-car.ru/'
    }
  },
  {
    route: '/reviews',
    Component: MainApp,
    subheader: {},
    meta: {}
  },
  {
    route: '/contacts',
    Component: Contacts,
    subheader: {
      parentName: 'Главная',
      listName: 'Компания',
      pageName: 'Контакты'
    },
    meta: {
      title: 'CreditCar | Деньги под залог автомобиля в СПб срочно',
      description: 'Пройдите проверку онлайн и получи до 1 000 000 руб за 30 мин в СПб под залог ПТС. Прозрачно и честно, не требуется подтверждать доход, без поручителей, перерасчет % по дням при досрочном погашении. Состоим в реестре ЦБ.',
      canonical: 'https://credit-car.ru/contacts'
    }
  },
  {
    route: '/about',
    Component: About,
    subheader: {
      parentName: 'Главная',
      listName: 'Компания',
      pageName: 'О компании'
    },
    meta: {
      title: 'CreditCar | Займ под залог ПТС в СПб',
      description: 'CreditCar Получи деньги под залог авто в СПб за 30 мин по ставке от 1,5% в мес. Без поручителей. Удобный расчет. Быстро и просто. Выгодные условия. Надежная компания. Состоим в реестре ЦБ.',
      canonical: 'https://credit-car.ru/about'
    }
  },
  {
    route: '/sitemap',
    Component: Sitemap,
    subheader: {
      parentName: 'Главная',
      listName: 'Компания',
      pageName: 'Карта сайта'
    },
    meta: {}
  },
  {
    route: '/services',
    Component: Services,
    subheader: {
      parentName: 'Главная',
      listName: 'Клиентам',
      pageName: 'Услуги'
    },
    meta: {
      title: 'CreditCar | Взять деньги под залог ПТС в СПб',
      description: 'Низкие % ставки, деньги в день обращения. Для оформления займа под ПТС требуется всего три документа: Паспорт РФ, ПТС, СТС. Перерасчет % по дням при досрочном погашении. До 80% от стоимости авто. На карту или наличными, онлайн погашение.',
      canonical: 'https://credit-car.ru/services'
    }
  },
  // {
  //   route: '/docs',
  //   Component: Docs,
  //   subheader: {
  //     parentName: 'Главная',
  //     listName: 'Компания',
  //     pageName: 'Документы'
  //   },
  //   meta: {}
  // },
  {
    route: '/info',
    Component: Info,
    subheader: {
      /*parentName: 'Главная',
      listName: 'Компания',*/
      pageName: ''
    },
    meta: {}
  },
  {
    route: '/samples',
    Component: Samples,
    subheader: {
      parentName: 'Главная',
      listName: 'Клиентам',
      pageName: 'Примеры выданных займов'
    },
    meta: {}
  },
  {
    route: '/auctions',
    Component: Auctions,
    subheader: {
      parentName: 'Главная',
      listName: 'Клиентам',
      pageName: 'Аукцион'
    },
    meta: {
      title: 'CreditCar | Займ под ПТС в СПб сниженный процент',
      description: 'CreditCar Выгодный займ под залог ПТС машины в СПб от 1,5% в мес до 1 000 000 руб. Прозрачные условия, работаем в рамках законодательства. Без скрытых платежей и комиссий. Получи деньги и пользуйся машиной как раньше.',
      canonical: 'https://credit-car.ru/auctions'
    }
  },
  {
    route: '/questions',
    Component: Questions,
    subheader: {
      parentName: 'Главная',
      listName: 'Клиентам',
      pageName: 'Вопросы и ответы'
    },
    meta: {
      title: 'CreditCar | Оформить залог под ПТС в СПб',
      description: 'CreditCar Залог под ПТС авто в СПб. Деньги на любые нужды без посредников, без подтверждения доходов. Не требуется наличие КАСКО и ОСАГО. Не вносим записи в ПТС, получи деньги за 30 мин в день обращения наличными или на карту.',
      canonical: 'https://credit-car.ru/questions'
    }
  },
  {
    route: '/payment',
    Component: Pay,
    subheader: {
      parentName: 'Главная',
      listName: 'Клиентам',
      pageName: 'Онлайн оплата'
    },
    meta: {}
  },
  {
    route: '/verify/success',
    Component: VerifyResult,
    subheader: {

    },
    meta: {}
  },
  {
    route: '/verify/fail',
    Component: VerifyFail,
    subheader: {
      
    },
    meta: {}
  },
  {
    route: '/admin/cards',
    Component: CardsList,
    subheader: {
      
    },
    meta: {}
  },
  {
    route: '/admin/pay',
    Component: CardsPay,
    subheader: {
      
    },
    meta: {}
  },

  {
    route: '/payment/success',
    Component: Success, // Insted Pay use Success component
    subheader: {
      parentName: 'Главная',
      listName: 'Клиентам',
      pageName: 'Оплата прошла успешно'
    },
    meta: {}
  },
  {
    route: '/payment/fail',
    Component: Success, // Insted Pay use Fail component
    subheader: {
      parentName: 'Главная',
      listName: 'Клиентам',
      pageName: 'Оплата была отклонена'
    },
    meta: {}
  },
  {
    route: '/test/payment/success',
    Component: Success, // Insted Pay use  test Success component
    subheader: {
      parentName: 'Главная',
      listName: 'Клиентам',
      pageName: 'Оплата прошла успешно'
    },
    meta: {}
  },
  {
    route: '/test/payment/fail',
    Component: Success, // Insted Pay use test Fail component
    subheader: {
      parentName: 'Главная',
      listName: 'Клиентам',
      pageName: 'Оплата была отклонена'
    },
    meta: {}
  },
  {
    route: '/methods',
    Component: Methods,
    subheader: {
      parentName: 'Главная',
      listName: 'Клиентам',
      pageName: 'Способы оплаты'
    },
    meta: {
      title: 'CreditCar | Кредит под залог ПТС в СПб',
      description: 'CreditCar взять кредит под ПТС до 1 000 000 руб за 30 мин с любой КИ. Никаких скрытых платежей. Конфиденциально. Без отметок в ПТС. Оставить заявку за 1 мин. Без справок и поручителей. В рамках законодательства.',
      canonical: 'https://credit-car.ru/methods'
    }
  },
  {
    route: '/action',
    Component: ActionFriend,
    subheader: {
      parentName: 'Главная',
      listName: 'Приведи друга',
      pageName: 'Отправить заявку'
    },
    meta: {}
  },
  /*{
    route: '/loans',
    Component: Loans,
    subheader: {
      parentName: 'Главная',
      listName: 'Предпринимателям',
      pageName: 'Займы предпринимателям'
    }
  },*/
];
