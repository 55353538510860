import * as React from 'react';

import { Button as AntdButton } from 'antd';

import style from './style.module.css';

const stylesClasses: any = {
  old: style.base, // style.old,
  default: style.base,
  yellow: style.yellow,
  orange: style.orange
};

export function Button({
  children = 'Отправить',
  styleName = 'default',
  className = '',
  ...props
}: {
  [key: string]: any
}) {
  let styleClassName = stylesClasses[styleName] || stylesClasses['default'];

  return (
    <AntdButton
      className={`${style.button} ${styleClassName + ''} ${className}`}
      htmlType="submit"
      {...props}
    >
      {children}
      <div className={style.blick}></div>
    </AntdButton>
  );
}
