import * as serviceWorker from './serviceWorker';
import * as React from "react";
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { configureStore } from 'app/store';
import {Router, Switch} from 'react-router';
import { App } from './app';
import ScrollToTop from './app/components/ScrollToTop';

import './assets/reset.css';
import {YMInitializer} from "react-yandex-metrika";

if (process.env.NODE_ENV !== 'development') {
    console.log = () => null;
}

// prepare store
const history = createBrowserHistory();
const store = configureStore();

ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            <ScrollToTop>
                <YMInitializer accounts={[86560641]}
                    options={{
                        clickmap:true,
                        trackLinks:true,
                        accurateTrackBounce:true,
                        webvisor:true
                    }}
                />
                <App />
            </ScrollToTop>
        </Router>
    </Provider>,
    document.getElementById('root')
);

serviceWorker.unregister();
