import * as React from 'react';
import { Button } from 'app/components/Button'
// import { Link } from 'react-router-dom';

import style from './style.module.css';
import check from 'assets/icons/index/check.png';

export interface Steps {
    showForm: any;
}

export class Steps extends React.Component<Steps> {
  static defaultProps: Partial<Steps> = {};

  render() {
    return (
      <section className={style.steps}>
        <h1>Как получить деньги?</h1>
        <article>
          <div>
            Что бы получить займ, Вам потребуется всего несколько шагов:
            <ul>
              <li>
                <img src={check} alt="" />
                Оставить контакт для звонка менеджера, мы подберем лучшие условия для вас.
              </li>
              <li>
                <img src={check} alt="" />
                Пройти проверку на юридическую чистоту вашего автомобиля.
              </li>
              <li>
                <img src={check} alt="" />
                Приехать в офис на подписание договора в рамках законодательства РФ.
              </li>
              <li>
                <img src={check} alt="" />
                Моментально получить деньги удобным для вас способом.
              </li>
            </ul>
          </div>
          <div>
            <div>
              <b>Небходимые документы:</b>
              <br />
              Паспорт РФ, ПТС, СТС
            </div>
          </div>
        </article>
        <article>
          <div>
            Оставить
            <br />
            заявку
          </div>
          <div className={style.arrow} />
          <div>
            Оформить
            <br />
            договор
          </div>
          <span className={style.arrow} />
          <div>
            Получить
            <br />
            деньги
          </div>
        </article>
        <Button
          className={style.submitButton}
          onClick={() => this.props.showForm('SimpleForm', false)}
          styleName={'old'}
        >
          ОСТАВИТЬ ЗАЯВКУ
        </Button>
        <a className={'moreAbout'} onClick={() => this.props.showForm('SendForm', false)}>
          Выслать документы на проверку
        </a>
      </section>
    );
  }
}
