import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';
import classnames from 'classnames';

import style from './style.module.css';
import pts from 'assets/img/pts.png';
import sts from 'assets/img/sts.png';
import questIcon from 'assets/img/question.png';
import pass from 'assets/img/pass.png';

export interface Questions extends RouteComponentProps<void> {}

export class Questions extends React.Component<Questions & any, { [key: string]: any }> {
  static defaultProps: Partial<Questions> = {};
  [key: string]: any;
  constructor(props: Questions, context?: any) {
    super(props, context);
    let state: any = {};
    this.questions.map(({ id }) => {
      state['open' + id] = false;
    });
    this.state = state;
    this.toggleQuestion = this.toggleQuestion.bind(this);
  }

  questions = [
    {
      id: '22342',
      question: 'Какие документы нужно предоставить для получения займа?',
      answer: 'Всего три документа: Паспорт РФ, СТС, ПТС',
      img: [pass, sts, pts]
    },
    {
      id: '22343',
      question: 'Какие скрытые комиссии при получении займа?',
      answer:
        'Дополнительные, либо скрытые комиссии не предусмотрены. Вы получаете сумму займа в полном объеме, одобренную сотрудниками «Creditcar».',
      img: []
    },
    {
      id: '22345',
      question: 'Как получить деньги?',
      answer:
        'Оставить номер телефона на сайте Creditcar, наш менеджер с вами свяжется для уточнения информации о предмете залога. Пройти проверку вашего автомобиля, либо приехать сразу в офис с документами на осмотр. После проверки вы сразу сможете получить наличные.',
      img: []
    }
  ];

  toggleQuestion(id: any) {
    this.setState({
      ['open' + id]: !this.state['open' + id]
    });
  }

  componentWillReceiveProps(nextProps: any) {
    /*if(nextProps.myProp !== this.props.myProps) {
      // nextProps.myProp имеет другое значение, чем наше текущее myProps
      // поэтому мы можем что-нибудь рассчитать базируясь на новом значении.
    }*/
  }

  render() {
    return (
      <section className={style.questions}>
        <h1>Популярные вопросы</h1>
        <article>
          {this.questions.map(({ question, answer, img, id }, i) => (
            <section
              key={i}
              className={classnames(style.item, this.state['open' + id] ? style.showAnswer : '')}
            >
              <div
                onClick={() => {
                  if (isMobileOnly) {
                    this.toggleQuestion(id);
                  }
                }}
              >
                <img src={questIcon} alt="" />
                <div>
                  <span>{question}</span>
                </div>
              </div>
              <article
                className={classnames(style.answerWrapper, img.length > 0 ? '' : style.offImg)}
              >
                <div>{answer}</div>
                <div>
                  {img.map((item, j) => (
                    <img key={j} src={item} alt="" />
                  ))}
                </div>
              </article>
            </section>
          ))}
        </article>
        <Link to={'/questions'} className={'moreAbout'}>
          Все вопросы и ответы
        </Link>
      </section>
    );
  }
}
