import * as React from 'react';

import style from './style.module.css';
import car1 from 'assets/img/car1.png';
import car2 from 'assets/img/car2.png';
import car3 from 'assets/img/car3.png';
import up from 'assets/img/up.png';


const currencyFormatter = require('currency-formatter');

export interface Samples {}

export class Samples extends React.Component<Samples, any> {
  static defaultProps: Partial<Samples> = {};

  examples = [
    {
      img: car1,
      name: 'Mercedes-Benz GL-Class, 2007',
      time: '6 месяцев',
      desc: 'Под залог ПТС ',
      cost: 5000000
    },
    {
      img: car2,
      name: 'Audi Q5, 2011',
      time: '10 месяцев',
      desc: 'Под залог ПТС ',
      cost: 500000
    },
    {
      img: car3,
      name: 'Hyundai Elantra V, 2002',
      time: '12 месяцев',
      desc: 'Под залог ПТС ',
      cost: 70000
    },
    {
      img: car1,
      name: 'Mercedes-Benz GL-Class, 2007',
      time: '6 месяцев',
      desc: 'Под залог ПТС ',
      cost: 5000000
    },
    {
      img: car2,
      name: 'Audi Q5, 2011',
      time: '10 месяцев',
      desc: 'Под залог ПТС ',
      cost: 500000
    },
    {
      img: car3,
      name: 'Hyundai Elantra V, 2002',
      time: '12 месяцев',
      desc: 'Под залог ПТС ',
      cost: 70000
    }
  ];

  render() {
    return (
      <div className={style.samples}>
        {this.examples.map(({ img, name, time, desc, cost }, i) => (
          <article key={i} className={style.example}>
            <img src={up} alt="" />
            <img src={img} alt="" />
            <div>
              <h3>{name}</h3>
              <p>Срок займа: {time}</p>
              <p>{desc}</p>
            </div>
            <div>
              <p>Выдана сумма</p>
              <div>
                {currencyFormatter.format(cost, {
                  code: 'RUB',
                  precision: 0
                })}
              </div>
            </div>
          </article>
        ))}
      </div>
    );
  }
}
