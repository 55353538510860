import axios from 'axios';

export function fetchData(config = {}, message = 'Please, check your internet connection.') {
  if (localStorage.getItem('jwtToken')) {
    axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('jwtToken')}`;
  }
  return axios(config)
      .then(resp => resp.data)
      .catch(error => ({ error: true, message }));
}

export function fetchAuthUser(data: { number: string; password: string }) {
  return fetchData(
    {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/auth`,
      data
    },
    'Unable to log in. Please, check your internet connection.'
  )//test data
    .then(() => ({ data: { token: 'sdfsdfsdf434534fxsvfsdvsdf32red' } }));
}

export function fetchPaymentForm(data: {

}) {
    return fetchData(
        {
            method: 'post',
            url: `https://credit-car.ru/api/payment`,
            data,
        }
    )
}
