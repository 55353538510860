import * as React from 'react';
import style from './style.module.css';
import {Button} from "../../Button";

export class FriendSuccess extends React.PureComponent<any> {
  render() {
    return (
      <section className={style.success}>
        <h2>Благодарим Вас за участие в акции «Приведи друга- получи 4000 рублей»</h2>
        <h4>Ваша заявка успешно принята!</h4>
        <p>
          В ближайшее время с Вашим другом свяжется наш сотрудник для уточнения условий предоставления займа.
        </p>
        <Button
            className={style.submitButton}
            onClick={this.props.closeForm}
            styleName={'old'}
        >
          ОК
        </Button>
      </section>
    );
  }
}
