import * as React from 'react';

function Success() {

    return (<div>

    </div>)
}

export {
    Success
};

