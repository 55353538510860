import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import { Helmet } from 'react-helmet';

import { Wrapper as MainWrapper } from 'app/components/Wrapper';
import { mainRoutes } from 'app/routes';
// import { Wrapper as AccountWrapper } from 'app/components/AccountWrapper';
// import { accountRoutes } from 'app/routes.lk';

export const App = function() {
  return (
  <Switch>
    {mainRoutes.map(({ route, Component, subheader = {}, meta = {} }, i) => {
      return (        
        <Route
          key={i}
          path={route}
          exact
          render={(props) => (
            <div className="App">
              <Helmet key={(i + 1) * 100} title={meta.title}>
                <title>{meta.title}</title>
                <meta name="title" content={meta.title} />
                <meta name="description" content={meta.description} />
                <link rel="canonical" href={meta.canonical} />
                <meta name="og:description" content={meta.description} />
              </Helmet>
              <MainWrapper RootComponent={Component} subheader={subheader} {...props} />
            </div>
          )}
        />        
      );
    })}
    {/*
    // Личный кабинет
    {accountRoutes.map(({ route, Component, subheader = {} }, i) => {
      return (
          <Route
              key={i}
              path={route}
              exact
              render={(props) => (
                  <AccountWrapper RootComponent={Component} subheader={subheader} {...props} />
              )}
          />
      );
    })}*/}
    <Route render={() => <Redirect to="/" />} />
  </Switch>
)};
