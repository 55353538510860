import * as React from 'react';
import style from './style.module.css';

export class SubHeader extends React.Component<any, any, any> {
  constructor(props: any) {
    super(props);
  }

  render() {
    let { parentName = '', listName = '', pageName = '' } = this.props.data;
    let showPath = parentName && listName;
    return (
      <div className={style.subHeader} style={/*!showPath ? { borderBottom: '0' } : */{}}>
        {showPath ? (
          <div>
            <span><a href="/">{parentName}</a></span>/<span>{listName}</span>
          </div>
        ) : (
          ''
        )}
        <h1>{pageName}</h1>
      </div>
    );
  }
}
