/*export function omit<T extends object, K extends keyof T>(target: T, ...omitKeys: K[]): Omit<T, K> {
  return (Object.keys(target) as K[]).reduce(
    (res, key) => {
      if (!omitKeys.includes(key)) {
        res[key] = target[key];
      }
      return res;
    },
    {} as any
  );
}*/

export function parseNumber(value: any) {
  console.log('parser', value);
  const regexp = /\+7\s?|[^0-9]/g;
  if (!value.length) {
    return value;
  } else {
    if (+value.replace(regexp, '').length > 10) {
      value = value.replace(regexp, '');
      let newValue = isNaN(+value.substring(0, 10))
        ? value.substring(0, 10)
        : +value.substring(0, 10);
      return newValue;
    }
    return value.replace(regexp, '');
  }
}

export function formatNumber(value: any) {
  if (value.toString().length === 0) {
    return value;
  }
  let newValue = value +'';
  console.log('Formatter value:', newValue);
  if (newValue.length > 10) {
    console.log('first');
    newValue = isNaN(+value.substring(0, 10)) ? value.substring(0, 10) : +value.substring(0, 10);
    console.log('Formatter newvalue:', newValue);
    return `+7 ${newValue}`;
  } else {
    console.log('second');
    if (newValue[0] === '8' || (newValue[0] === '7') || (newValue[0] === '0' && newValue.length === 1) ) {
      console.log('Formatter works');
      return '+7 ';
    }
    if (newValue === ' ') {
      console.log('!value');
      return '+7 ';
    }

    return `+7 ${(+newValue + '').replace(/[^0-9]/g, '')}`;
  }
}

export function formatName(value: any) {
  value += '';
  return value.replace(/[^А-ЯЁа-яё\s]/g, '');
}

export function formatFullName(value: any) {
  value += '';
  return value.replace(/[^A-ZА-ЯЁа-яё|\s]/g, '');
}

export function formatContract(value: any) {
  value += '';
  return value.replace(/[^0-9]/g, '');
}

export function formatEmail(value: any) {
  value += '';
  return value.replace(/[А-ЯЁа-яё]/g, '');
}

export function formatDate(value: any) {
  console.log(value);
  value += '';
  return value; /*.replace(/[^0-9-.]/g, '')*/
}
