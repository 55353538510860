import * as React from 'react';
import {RouteComponentProps} from 'react-router';
import {BackTop, Icon, Modal} from 'antd';
import {Link} from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';
import classNames from 'classnames';

import style from './style.module.css';
import {WrappedCustomHeader as MainHeader} from 'app/components/Header';
import {WrappedCustomFooter as MainFooter} from 'app/components/Footer';
import {SubHeader} from 'app/components/Subheader';
import {Notificator} from 'app/components/Notificator';
import {WrappedCustomSendForm as SendForm} from 'app/components/SendForm';
import ym from 'react-yandex-metrika';
import {WrappedCustomFriendForm as FriendForm} from 'app/components/FriendForm';
import * as currencyFormatter from "currency-formatter";

import bib_bob from 'assets/icons/index/bib_bob_2x.png';
import whatsapp from 'assets/icons/index/wa.png';
import telegram from 'assets/icons/index/tg.png';
// import { useLocation } from 'react-router-dom';

export interface Wrapper extends RouteComponentProps<void> {
    [key: string]: any;
    RootComponent: any;
    subheader: any;
}

export class Wrapper extends React.Component<Wrapper & any, any> {

    static defaultProps: Partial<Wrapper> = {};

    constructor(props: Wrapper, context?: any) {
        super(props, context);
        this.state = {
            notification: true,
            showMenu: false,
            renderedMenu: '',
            visibleMargin: false,
            visibleSendForm: false,
            visibleFriendForm: false,
        };
        this.toggleMenu = this.toggleMenu.bind(this);
        this.updateMenu = this.updateMenu.bind(this);
        this.closeNotification = this.closeNotification.bind(this);
        this.toggleMargin = this.toggleMargin.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.renderLink = this.renderLink.bind(this);
        this.wrapperHandlerModalFunc = this.wrapperHandlerModalFunc.bind(this);
        this.wrapperHandlerNotificationFunc = this.wrapperHandlerNotificationFunc.bind(this);
    }

    componentDidMount(): void {
        ym('hit', window.location.pathname);
    }

    toggleMenu = (menu: any) => {
        this.setState({
            showMenu: !this.state.showMenu,
            renderedMenu: menu
        });
    };

    updateMenu = (menu: any) => {
        console.log('update!');
        this.setState({
            renderedMenu: menu
        });
    };

    closeNotification = () => {
        this.setState({
            notification: false
        });
    };

    toggleMargin = (val: boolean) => {
        this.setState({
            visibleMargin: val
        });
    };

    handleCancel = (e: any) => {
        console.log(e);
        this.setState({
            visibleSendForm: false,
            visibleFriendForm: false,
            simpleType: false
        });
    };

    wrapperHandlerNotificationFunc = () => {
        document.location.replace('/action');
    }

    wrapperHandlerModalFunc = (name: string, value = false) => {
        console.log('visible' + name);
        this.setState({
            ['visible' + name]: true,
            simpleType: value
        });
    };

    renderLink = (link: string, title: string, clickFunc: any) =>
        clickFunc ? (
            <span onClick={clickFunc}>{title}</span>
        ) : (
            <Link to={link}>
                {title}
            </Link>
        );

    render() {
        const {RootComponent} = this.props;
        let showMenuClassName = this.state.showMenu ? style.wrapperMenu : '';
        let showSubheader =
            Object.keys(this.props.subheader).length === 0 && this.props.subheader.constructor === Object;

      //   let {notification} = this.state;
      //   let notification_message = (
      //       <span>
      //   Уважаемые клиенты, у нас изменились <Link to={'/methods'}>реквизиты</Link> оплаты!
      // </span>
      //   );
        let {notification} = this.state;

        return (
            <div className={classNames(style.wrapper, showMenuClassName)}>
                {notification && window.location.pathname == '/' ? (
                    // <Notificator onClose={this.closeNotification}>{notification_message}</Notificator>
                    <Notificator onAction={this.wrapperHandlerNotificationFunc} onClose={this.closeNotification}>
                        {!isMobileOnly ? (<img src={bib_bob} alt="" />) : ('')}
                        <div className={'message'}><div>Акция! Приведи{isMobileOnly ? (<br/>) : (' ')}друга - получи 4000 {currencyFormatter.findCurrency('RUB').symbol}</div>
                        <button className={'ant-btn'}>УЧАСТВОВАТЬ</button></div>
                    </Notificator>
                ) : (
                    <div/>
                )}
                <MainHeader
                    notificator={notification}
                    toggleMargin={this.toggleMargin}
                    toggleMenu={this.toggleMenu}
                    updateMenu={this.updateMenu}
                    renderLink={this.renderLink}
                    showForm={this.wrapperHandlerModalFunc}
                    redirectToContacts={(func: any) => func()}
                />
                {this.state.renderedMenu}
                <section className={this.props.subheader.pageName == '' ? style.sectionNoMargin : ''}>
                    {!showSubheader ? <SubHeader data={this.props.subheader}/> : <div/>}
                    <RootComponent showForm={this.wrapperHandlerModalFunc}/>
                </section>
                <MainFooter renderLink={this.renderLink} showForm={this.wrapperHandlerModalFunc}/>
                {/*<BackTop>
                    <div className={style.mover}>
                        <Icon type="arrow-up" theme="outlined"/>
                    </div>
                </BackTop>*/}
                <div className={style.messengers}>
                    <a href="https://wa.me/79217826164" target="_blank"><img src={whatsapp} /></a>
                    <a href="https://t.me/creditcar_pod_pts" target="_blank"><img src={telegram} /></a>
                </div>
                <Modal
                    visible={this.state.visibleSendForm}
                    onOk={this.handleCancel}
                    onCancel={this.handleCancel}
                    className={style.modalSendForm}
                    destroyOnClose={true}
                >
                    <SendForm onCancel={this.handleCancel} showSum={this.state.simpleType}/>
                </Modal>
                <Modal
                    visible={this.state.visibleFriendForm}
                    onOk={this.handleCancel}
                    onCancel={this.handleCancel}
                    className={style.modalFriendForm}
                    destroyOnClose={true}
                >
                    <FriendForm onCancel={this.handleCancel}/>
                </Modal>
            </div>
        );
    }
}
