import * as React from 'react';

import style from './style.module.css';

class Tabber extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      leftHeader: true,
      leftContent: true
    };
  }

  toogleHeader = (val: any) => {
    if (this.state.leftHeader !== val) {
      this.setState({
        leftHeader: val
      });
      setTimeout(() => {
        this.setState({
          leftContent: val
        });
      }, 200);
    }
  };

  render() {
    const { leftHeader, leftContent } = this.state;
    const {
      leftHeaderText,
      rightHeaderText,
      leftContentItem,
      rightContentItem,
      customTabberClass = ''
    } = this.props;

    let activeHeaderClass = leftHeader ? style.activeLeftHeader : style.activeRightHeader;

    interface CSSProperties {}

    let none: CSSProperties = {
      opacity: '0'
    };
    return (
      <div className={`${style.Tabber} ${customTabberClass}`}>
        <header className={activeHeaderClass}>
          <div
            onClick={() => {
              this.toogleHeader(true);
            }}
          >
            {leftHeaderText}
          </div>
          <div
            onClick={() => {
              this.toogleHeader(false);
            }}
          >
            {rightHeaderText}
          </div>
        </header>
        {leftContent ? (
          <div style={!leftHeader ? none : {}}>{leftContentItem}</div>
        ) : (
          <div style={leftHeader ? none : {}}>{rightContentItem}</div>
        )}
      </div>
    );
  }
}

export default Tabber;
