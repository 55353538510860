import * as React from 'react';
import classnames from 'classnames';

import pts from 'assets/img/pts.png';
import sts from 'assets/img/sts.png';
import questIcon from 'assets/img/question.png';
import pass from 'assets/img/pass.png';
import man from 'assets/img/support_man.png';

import style from './style.module.css';

export interface Questions {
    visibleSendForm: any;
}

export class Questions extends React.Component<Questions, { [key: string]: any }> {
  static defaultProps: Partial<Questions> = {
    visibleSendForm: false
  };
  [key: string]: any;
  constructor(props: Questions, context?: any) {
    super(props, context);
    let state: any = {};
    this.commonQuestions.map(({ id }) => {
      state['open' + id] = false;
    });
    this.state = state;
    this.toggleQuestion = this.toggleQuestion.bind(this);
  }

  commonQuestions = [
    {
      id: '22342',
      question: 'Какие документы нужно предоставить для получения займа?',
      answer: 'Всего три документа: Паспорт РФ, СТС, ПТС',
      img: [pass, sts, pts]
    },
    {
      id: '22343',
      question: 'Какие скрытые комиссии при получении займа?',
      answer:
        'Дополнительные, либо скрытые комиссии не предусмотрены. Вы получаете сумму займа в полном объеме, одобренную сотрудниками «Creditcar».',
      img: []
    },
    {
      id: '22345',
      question: 'Как получить деньги?',
      answer:
        'Оставить номер телефона на сайте Creditcar, наш менеджер с вами свяжется для уточнения информации о предмете залога. Пройти проверку вашего автомобиля, либо приехать сразу в офис с документами на осмотр. После проверки вы сразу сможете получить наличные.',
      img: []
    }
  ];

  loanQuestions = [
    /*{
      id: '22347',
      question: 'Какие документы предоставить для получения займа?',
      answer:
        'Оставить контакт для звонка менеджера, чтобы подобрать лучшие условия для Вас пройти проверку, приехать в офис на подписание договора, получить деньги наличными. Либо сразу приехать в офис на вашем автомобиле.',
      img: []
    },*/
    {
      id: '22348',
      question: 'Где проходит оформление займа?',
      answer:
        'Оформление займа, подписание всех документов и выдача наличных происходит в нашем офисе по адресу: Санкт-Петербург, пр. Коломяжский д. 18, БЦ «Норд-Хаус».',
      img: []
    },
    {
      id: '22349',
      question: 'Вносится ли запись в ПТС/ПСМ о залоге?',
      answer:
        'Нет, никакие записи или отметки в паспорт транспортного средства или в паспорт самоходной машины не вносятся. Так же мы не просим ставить подпись в ПТС.',
      img: []
    },
    {
      id: '22333',
      question:
        'Требуется ли переоформление транспортного средства/самоходной машины для получения займа?',
      answer:
        'Нет, мы не переоформляем на себя Ваше транспортное средство, Вы так же, как и раньше остаетесь владельцем транспортного средства.',
      img: []
    },
    {
      id: '22334',
      question:
        'Можно ли оформить займ по генеральной доверенности на транспортное средство или самоходную машину?',
      answer:
        'Нет, займ оформляется только на действующего собственника транспортного средства по ПТС.',
      img: []
    },
    {
      id: '22335',
      question:
          'Какие договоры подписываются для получения займа?',
      answer:
          'При оформлении займа подписываются индивидуальные условия, согласие на обработку персональных данных, заявление на предоставление займа, акт приема-передачи ПТС на ответственное хранение.',
      img: []
    }
  ];

  failQuestions = [
    {
      id: '22351',
      question: 'Заберут ли транспортное средство/самоходную машину если будет допущена просрочка?',
      answer:
        'Мы максимально лояльно относимся к нашим клиентам, и в случае просрочки оплаты займа, подходим к каждому клиенту индивидуально и предлагаем различные варианты выхода из данной ситуации. ',
      img: []
    },
    {
      id: '22352',
      question: 'Каков размер штрафа/пени при просрочке платежа?',
      answer:
        'Максимальный процент неустойки регламентируется пунктом 21 статьи 5 Федерального закона № 353-ФЗ от 21 декабря 2013 года, из расчета 20% годовых.',
      img: []
    }
  ];

  toggleQuestion(id: any) {
    this.setState({
      ['open' + id]: !this.state['open' + id]
    });
  }

  renderQA = (
    array: {
      id: string;
      question: string;
      answer: string;
      img: any[];
    }[]
  ) => {
    return (
      <article>
        {array.map(({ question, answer, img, id }, i) => (
          <section
            key={i}
            className={classnames(style.item, this.state['open' + id] ? style.showAnswer : '')}
          >
            <div
              onClick={() => {
                this.toggleQuestion(id);
              }}
            >
              <img src={questIcon} alt="" />
              <div>
                <span>{question}</span>
              </div>
            </div>
            <div>
              <article
                className={classnames(style.answerWrapper, img.length > 0 ? '' : style.offImg)}
              >
                <div>{answer}</div>
                <div className={style.arrow} />
                <div>
                  {img.map((item, j) => (
                    <img key={j} src={item} alt="" />
                  ))}
                </div>
              </article>
              <img src={man} alt="" />
            </div>
          </section>
        ))}
      </article>
    );
  };

  render() {
    console.log(this.props);
    return (
      <div className={style.questions}>
        <h2>Общие вопросы</h2>
        {this.renderQA(this.commonQuestions)}
        <h2>Оформление займа</h2>
        {this.renderQA(this.loanQuestions)}
        <h2>Просрочка займа</h2>
        {this.renderQA(this.failQuestions)}
      </div>
    );
  }
}
