import * as React from 'react';
import {Menu, Dropdown, Icon, Popover} from 'antd';
import classNames from 'classnames';
import Form, {FormComponentProps} from 'antd/lib/form';
import {Redirect, RouteComponentProps, withRouter} from 'react-router';
import {Link} from 'react-router-dom';
//import { Link } from 'react-scroll';
import {scroller as scroll} from 'react-scroll';

import {Button} from '../Button';
import {WrappedCustomSimpleForm as SimpleForm} from '../SimpleForm';

import style from './style.module.css';
import logo from 'assets/img/newlogo.png';
import logoWebp from 'assets/img/webp/newlogo.webp';
import dropdownIcon from 'assets/img/header_dropdown.png';
import vk_green from 'assets/img/vk_green.png';
import insta_green from 'assets/img/insta_green.png';
import dropdownIconClose from 'assets/img/header_close.png';
import callIcon from 'assets/img/header_call.png';
import phone from 'assets/img/phone.svg';
import pin from 'assets/icons/index/pin.svg';
import user from 'assets/img/private.svg';

interface HeaderTypeForm {
    [key: string]: any;
}

type HeaderType = HeaderTypeForm & RouteComponentProps<HeaderTypeForm> & FormComponentProps<HeaderTypeForm>;

/*
 *
 * TODO: Implement location detecting for select initial location
 *
 * */

export class Header extends React.Component<HeaderType, { [key: string]: any; }> {
    [key: string]: any;

    constructor(props: HeaderType) {
        super(props);
        this.moveArrow1 = this.moveArrow1.bind(this);
        this.moveArrow2 = this.moveArrow2.bind(this);
        this.headerRef = React.createRef();
        let curLocation = window.location.hash == '#mos'
            ? 'Москва'
            : 'Санкт-Петербург';


        this.state = {
            sticky: false,
            visibleMenuForm: false,
            moveArrow1: false,
            moveArrow2: false,
            toggleDropdown: false,
            error: false,
            redirectToContacts: false,
            location: curLocation
        }
    }

    selectMenu: any[] = ['Санкт-Петербург' /*, 'Москва'  /*, 'Челябинск'*/];

    menu1: any[] = [
        [
            {
                title: 'О компании',
                link: '/about'
            },
            // {
            //     title: 'Документы',
            //     link: '/docs'
            // },
            /*{
                title: 'Инвестиции',
                disabled: true,
                link: '/'
            },
            {
                title: 'Франшиза',
                disabled: true,
                link: '/'
            },*/
            {
                title: 'Контакты',
                link: '/contacts'
            },
            {
                title: 'Отзывы',
                link: '/reviews',
                clickFunc: (e: any) => {
                    e.preventDefault();
                    window.history.pushState({}, 'Creditcar', '/reviews');
                    this.props.toggleMenu(this.renderMobileMenu(this.state.location));
                    this.setState({toggleDropdown: !this.state.toggleDropdown});
                    // this.scrollToElement('testimonials', true)
                    let window_width = window.innerWidth;
                    if (window_width > 460) {
                        const el = document.getElementById('reviews')
                        if (el) {    
                            el.scrollIntoView({behavior: "smooth"})
                        }
                    }
                    else {
                        this.scrollToElement('testimonials', true)
                    }
                }
            }
            /*{
                title: 'Отзывы',
                link: '#',
                clickFunc: (e: any) => {
                    e.preventDefault();
                    this.scrollToElement('testimonials')
                }
            }*/
        ]
    ];

    labels2: any = ['Частным клиентам', 'Предпринимателям'];

    menu2: any[] = [
        [
            {
                title: 'Займы',
                link: '/services'
            },
            {
                title: 'Способы оплаты',
                link: '/methods'
            },
            {
                title: 'Выслать документы на проверку',
                link: '#',
                clickFunc: () => {
                    this.props.showForm('SendForm', false);
                }
            },
            {
                title: 'Аукционы',
                link: '/auctions'
            },
            {
                title: 'Вопросы и ответы',
                link: '/questions'
            },
            {
                title: 'Проверить карту',
                link: '/api/tinkoff/verify',
                clickFunc: (e: any) => {
                    e.preventDefault();
                    window.location.href = '/api/tinkoff/verify';
                }
            },
            /*{
                title: 'Примеры выданных займов',
                disabled: true,
                link: '/samples'
            }*/
        ],
        [
            {
                title: this.props.match.path !== '/' ? <Link to={'/'}>Займы предпринимателям</Link> : 'Займы предпринимателям',
                link: '#',
                clickFunc: (e: any) => {
                    e.preventDefault();
                    this.scrollToElement('owner', true)
                }

            }
        ]
    ];

    menu3: any[] = [
        {
            title: this.props.match.path !== '/' ? <Link to={'/'}>Займы предпринимателям</Link> : 'Займы предпринимателям',
            link: '#',
            clickFunc: () => {
                this.props.toggleMenu(this.renderMobileMenu(this.state.location));
                this.setState({toggleDropdown: !this.state.toggleDropdown});
                this.scrollToElement('owner', true)
            }
        }
    ];

    scrollToElement = (name: string, offset = true) => {
        let offsetHeight = offset ? -this.headerRef.current.offsetHeight || -50 : 0;
        // let window_width = window.innerWidth;
        // let offsetHeight = -window_width * 0.2;
        setTimeout(() => {
            scroll.scrollTo(name, {
                offset: offsetHeight,
                spy: true,
                smooth: true,
                duration: 1000
            })
        }, 500)
    };

    componentDidMount() {
        window.addEventListener('scroll', () => {
            let notificator: any = document.getElementById('notificator');

            if (window.pageYOffset > 0) {
                this.setState({
                    showBg: true
                });
            } else {
                this.setState({
                    showBg: false
                });
            }

            let notificatorHeight = notificator ? notificator.clientHeight - 10 : 0;

            if (window.pageYOffset > notificatorHeight + 20) {
                if (!this.state.sticky) {
                    this.props.toggleMargin(true);
                    this.setState({
                        sticky: true
                    });
                }
            } else {
                if (this.state.sticky) {
                    this.props.toggleMargin(false);
                    this.setState({
                        sticky: false
                    });
                }
            }
        });
    }

    renderMenu = (array: any[], labels = [], custom = true, callback = (item: any) => {
    }) => {
        if (custom) {
            return (
                <div
                    className={style.menu}
                    onClick={() => {
                        console.log('moveArrow');
                        callback(false);
                    }}
                >
                    <span className={style.menuArrow}>▲</span>
                    {array.map((item, i) => {
                        let renderMenuItem = (data: any, j: any) => {
                            let {title = '', link = '/', disabled = false, clickFunc = false} = data;
                            return (
                                <Menu.Item key={j} className={disabled ? 'disabled' : ''}>
                                    {this.props.renderLink(link, title, clickFunc)}
                                </Menu.Item>
                            )
                        };
                        return (
                            <div key={i}>
                                {labels[i] ? <h4>{labels[i]}</h4> : ''}
                                <Menu>{item.map((element: any, j: any) => renderMenuItem(element, j))}</Menu>
                            </div>
                        );
                    })}
                </div>
            );
        } else {
            let item = Array.isArray(array[0]) ? array[0] : array;
            return (
                <ul className={style.mobileList}>
                    {item.map((data: any, i: any) => {
                            let {title = '', link = '/', disabled = false, clickFunc = false} = data;
                            return (
                                <li key={i} className={disabled ? 'disabled' : ''}>
                                    {this.props.renderLink(link, title, clickFunc)}
                                </li>
                            )
                        }
                    )}
                </ul>
            );
        }
    };

    selectItem = (e: any, value: any) => {
        this.setState({location: value});
    };

    renderMenuForm = (decorator: any) => {
        return (
            <div className={classNames(style.menu, style.formSize)}>
                <span className={style.menuFormArrow}>▲</span>
                <SimpleForm onVisibleChange={this.handleVisibleChange} formMetricId={'top-form'}/>
            </div>
        );
    };

    renderSelectMenu = (array: any[], flag: boolean) => {
        return (
            <Menu className={classNames(style.locationMenu)}>
                {array.map((value: string, i) => (
                    <Menu.Item key={i}>
                        {i === 0 ? <span className={style.menuArrow}>▲</span> : ''}
                        <div
                            ref={this[value]}
                            className={this.state.location === value ? style.selectedLocation : ''}
                            onClick={(e) => {
                                this.selectItem(e, value);
                                //console.log()
                                /*if (window.location.pathname === '/') {
                                    console.log('Scroll!!!');
                                    scroller.scrollTo('Map', {
                                        duration: 1500,
                                        delay: 100,
                                        smooth: true,
                                        offset: -100
                                    });
                                } else {*/
                                    if (window.location.pathname !== '/contacts') {
                                        let redirect = () =>
                                            this.setState({
                                                redirectToContacts: true
                                            });
                                        this.props.redirectToContacts(redirect);
                                    }
                                //}

                                if (flag) {
                                    this.props.updateMenu(this.renderMobileMenu(value));
                                }
                            }}
                        >
                            {value}
                        </div>
                    </Menu.Item>
                ))}
            </Menu>
        );
    };

    renderMobileMenu = (location: any) => {
        let menuStructure = [
            {
                title: 'Компания',
                items: this.menu1
            },
            {
                title: 'Частным клиентам',
                items: this.menu2
            },
            {
                title: 'Предпринимателям',
                items: this.menu3
            },
        ];
        return (
            <div className={style.mobileMenu}>
                <section className={style.contacts}>
                    <a href={process.env.REACT_APP_LK_URL + '/login'}
                       className={classNames(style.userButton, 'disabled')}>
                        <img src={user} alt=""/>
                    </a>
                    <div>
                        <img src={pin} alt=""/>
                        <Dropdown
                            overlay={this.renderSelectMenu(this.selectMenu, true)}
                            trigger={['click']}
                            placement={'bottomCenter'}
                        >
                            <span>{location}</span>
                        </Dropdown>
                    </div>
                    <article>
                        <div className={style.phoneNumber}>
                            <img src={phone} alt=""/>
                            <a
                                href="tel:+78006009580
"
                            >
                                8-800-600-95-80
                            </a>
                        </div>
                        <div>бесплатный звонок по РФ</div>
                    </article>
                </section>
                {menuStructure.map(({title = '', items}, i) => (
                    <section key={i + title}>
                        <h2>{title}</h2>
                        {this.renderMenu(items, [], false)}
                    </section>
                ))}
                <section>
                    <a href="https://vk.com">
                        <img src={vk_green} alt=""/>
                    </a>
                    <a href="https://instagram.com">
                        <img src={insta_green} alt=""/>
                    </a>
                </section>
            </div>
        );
    };

    handleSubmit = () => {
        console.log('AUTH: ', this.props.auth);

        /*
         * TODO: ADD LOADING FOR BUTTON
         * */

        this.props.form.validateFields((err: object, {name, number}) => {
            let numberIsValid = false;
            if (number) {
                if (number.toString().length === 10 && number.toString()[0] !== '8') {
                    numberIsValid = true;
                }
            }
            if (!err && numberIsValid) {
            } else {
                console.log('ERROR::', err, name, number);

                if (!err || !number) {
                    return this.setState({
                        error: true,
                        message: 'Заполните обязательные поля'
                    });
                }
            }
        });
    };

    handleNumberChange = (number: any) => {
        if (!number || number.toString().length !== 10) {
            this.setState({
                validateNumberStatus: 'error',
                error: false
            });
        } else {
            this.setState({
                validateNumberStatus: 'success',
                error: false
            });
        }
    };

    handleErrorChange = () => {
        if (this.state.error) {
            this.setState({
                error: false
            });
        }
    };

    handleVisibleChange = () => {
        this.setState({
            visibleMenuForm: !this.state.visibleMenuForm
        });
    };

    moveArrow1(visible: any) {
        console.log('VISIBLE: ', visible);
        this.setState({
            moveArrow1: !this.state.moveArrow1
        });
    }

    moveArrow2(visible: any) {
        this.setState({
            moveArrow2: !this.state.moveArrow2
        });
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        let stickyClass = this.state.sticky ? style.stickyHeader : '';
        //let notificator: any = document.getElementById('notificator');
        /*let marginHeight = '0px';
        if (this.props.notificator) {
          marginHeight = '3.15vw';
        }*/
        let notificatorClass = this.props.notificator ? style.marginHeader : '';

        /*if (this.state.redirectToContacts) {
            if (this.state.location == "Москва") {
                return <Redirect to={'/contacts#mos'}/>;
            } else {
                return <Redirect to={'/contacts#spb'}/>;
            }
        } else {
            if (this.state.location == "Москва") {
                window.location.hash = '#mos';
            } else {
                window.location.hash = '#spb';
            }
        }*/

        console.log('THIS', this)

        return (
            <header id={'header'} ref={this.headerRef} className={classNames(style.header, stickyClass, notificatorClass)}>
                {this.props.renderLink('/', <picture>
                    <source type={"image/webp"}
                            srcSet={`${logoWebp}`}/>
                    <img src={logo} alt="CREDITCAR"/>
                </picture>)}
                <div>
                    <Dropdown
                        overlay={this.renderSelectMenu(this.selectMenu, true)}
                        trigger={['click']}
                        placement={'bottomCenter'}
                    >
                        <img src={pin} alt=""/>
                    </Dropdown>
                    <a href={process.env.REACT_APP_LK_URL + '/login'} className={style.userButton + ' disabled'}>
                        <img src={user} alt=""/>
                    </a>
                </div>
                <Dropdown
                    overlay={this.renderMenu(this.menu1, [], true, this.moveArrow1)}
                    trigger={['click']}
                    onVisibleChange={() => this.moveArrow1(true)}
                >
                    <div>
                        <Icon className={this.state.moveArrow1 ? style.arrowMoved : ''} type="caret-right"/>
                        Компания
                    </div>
                </Dropdown>
                <Dropdown
                    overlay={this.renderMenu(this.menu2, this.labels2, true, this.moveArrow2)}
                    trigger={['click']}
                    placement={(window.innerWidth < 900) ? 'bottomRight' : 'bottomLeft'}
                    onVisibleChange={() => this.moveArrow2(true)}
                >
                    <div>
                        <Icon className={this.state.moveArrow2 ? style.arrowMoved : ''} type="caret-right"/>
                        Клиентам
                    </div>
                </Dropdown>
                <section className={style.payment}>
                    {this.props.renderLink('/payment', 'Онлайн оплата')}
                    <Popover
                        content={this.renderMenuForm(getFieldDecorator)}
                        overlayClassName={style.menuForm}
                        trigger="click"
                        visible={this.state.visibleMenuForm}
                        placement="bottom"
                        onVisibleChange={this.handleVisibleChange}
                    >
                        <Button>ВЗЯТЬ ЗАЙМ</Button>
                    </Popover>
                </section>
                <section className={style.contacts}>
                    <div>
                        <img src={pin} alt=""/>
                        <Dropdown
                            overlay={this.renderSelectMenu(this.selectMenu, true)}
                            trigger={['click']}
                            placement={'bottomCenter'}
                        >
                            <span>{this.state.location}</span>
                        </Dropdown>
                        <a href={process.env.REACT_APP_LK_URL + '/login'} className={style.userButton + ' disabled'}>
                            <img src={user} alt=""/>
                        </a>
                    </div>
                    <div className={style.phoneNumber}>
                        <img src={phone} alt=""/>
                        <a
                            href="tel:+78006009580
"
                        >
                            8-800-600-95-80
                        </a>
                    </div>
                    <div>бесплатный звонок по РФ</div>
                </section>
                <a
                    href="tel:+78006009580
"
                >
                    <img src={callIcon} alt="Call"/>
                </a>
                <img
                    src={this.state.toggleDropdown ? dropdownIconClose : dropdownIcon}
                    onClick={() => {
                        this.props.toggleMenu(this.renderMobileMenu(this.state.location));
                        this.setState({toggleDropdown: !this.state.toggleDropdown});
                    }}
                    alt="Menu"
                />
            </header>
        );
    }
}

export const WrappedCustomHeader = Form.create<HeaderType>()(withRouter(Header));
