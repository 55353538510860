import React, {Component} from 'react';
import {Modal} from 'antd';
import {Element} from 'react-scroll';
import {Link} from 'react-router-dom'



import style from './index.module.css';
// @ts-ignore
import ReactPlayer from 'react-player/youtube';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 900 },
        items: 3,
    },
    tablet: {
        breakpoint: { max: 900, min: 460 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 460, min: 0 },
        items: 1,
    }
};

export interface Testimonials {
}

export class Testimonials extends React.Component<Testimonials, any> {
  static defaultProps: Partial<Testimonials> = {};

    constructor(props: Testimonials, context?: any) {
        super(props, context);
        this.state = {
            videos: [
                {
                    name: 'Михаил о компании CreditCar',
                    path: '/video/new/Михаил о компании CreditCar.mp4',
                    screenshot: '/video/new/screenshots/Михаил_001.png',
                    visible: false,
                },
                {
                    name: 'Сергей о компании CreditCar',
                    path: '/video/new/Сергей о компании CreditCar.mp4',
                    screenshot: '/video/new/screenshots/Сергей_002.png',
                    visible: false,
                },
                {
                    name: 'Мария отзыв о CreditCar',
                    path: '/video/new/Мария отзыв о CreditCar.mp4',
                    screenshot: '/video/new/screenshots/Мария_003.png',
                    visible: false,
                },
                {
                    name: 'Александр отзыв о CreditCar',
                    path: '/video/new/Александр отзыв о CreditCar.mp4',
                    screenshot: '/video/new/screenshots/Александр_004.png',
                    visible: false,
                },
                {
                    name: 'Андрей отзыв о CreditCar',
                    path: '/video/new/Андрей отзыв о CreditCar.mp4',
                    screenshot: '/video/new/screenshots/Андрей_005.png',
                    visible: false,
                },
                {
                    name: 'Айда отзыв о CreditCar',
                    path: '/video/new/Айда отзыв о CreditCar.mp4',
                    screenshot: '/video/new/screenshots/Айда_006.png',
                    visible: false,
                },
                /*{
                    path: '/video/001.mp4',
                    visible: false,
                },
                {
                    path: '/video/002.mp4',
                    visible: false,
                },
                {
                    path: '/video/003.mp4',
                    visible: false,
                },
                {
                    path: '/video/004.mp4',
                    visible: false,
                },
                {
                    path: '/video/005.mp4',
                    visible: false,
                },
                {
                    path: '/video/006.mp4',
                    visible: false,
                },
                {
                    path: '/video/007.mp4',
                    visible: false,
                },
                {
                    path: '/video/008.mp4',
                    visible: false,
                },
                {
                    path: '/video/009.mp4',
                    visible: false,
                },
                {
                    path: '/video/010.mp4',
                    visible: false,
                },
                {
                    path: '/video/011.mp4',
                    visible: false,
                },
                {
                    path: '/video/012.mp4',
                    visible: false,
                },
                {
                    path: '/video/013.mp4',
                    visible: false,
                },
                {
                    path: '/video/014.mp4',
                    visible: false,
                },
                {
                    path: '/video/015.mp4',
                    visible: false,
                },
                {
                    path: '/video/016.mp4',
                    visible: false,
                },*/
                /*'/video/001.mp4',
                '/video/002.mp4',
                '/video/003.mp4',
                '/video/004.mp4',
                '/video/005.mp4',
                '/video/006.mp4',
                '/video/007.mp4',
                '/video/008.mp4',
                '/video/009.mp4',
                '/video/010.mp4',
                '/video/011.mp4',
                '/video/012.mp4',
                '/video/013.mp4',
                '/video/014.mp4',
                '/video/015.mp4',
                '/video/016.mp4',*/
            ],
            visibleVideoModal: false,
            currentVideo: null
        }
    }

    
    handleCancel = (e: any) => {
        console.log(e);
        this.setState({
          visibleVideoModal: false,
          currentVideo: null,
        });
    };


    render() {
        return (
            <section id="testimonials" className={style.Testimonials}>
                <Link to='/reviews' id="reviews" className={style.anchor}></Link>
                {/*<Element name={'testimonials'}>*/}
                    <h1>Отзывы клиентов</h1>
                    <article>
                        <div className={style.carousel}>
                            <Carousel
                                swipeable={false}
                                draggable={false}
                                showDots={false}
                                responsive={responsive}
                                ssr={true} // means to render carousel on server-side.
                                infinite={true}
                                autoPlay={false}
                                autoPlaySpeed={1000}
                                keyBoardControl={true}
                                customTransition="all .5"
                                transitionDuration={500}
                                containerClass="carousel-container"
                                // removeArrowOnDeviceType={["tablet", "mobile"]}
                                //deviceType={this.props.deviceType}
                                dotListClass="custom-dot-list-style"
                                itemClass="carousel-item-padding-40-px"
                            >
                                {/*<div className={style.video} ><ReactPlayer url='https://youtu.be/AtEhgqSllXY' controls={true} /></div>
                                <div className={style.video} ><ReactPlayer url='https://youtu.be/Cb4PK36L6GY' controls={true} /></div>
                                <div className={style.video} ><ReactPlayer url='https://youtu.be/TwF9P8utpKc' controls={true} /></div>
                                <div className={style.video} ><ReactPlayer url='https://youtu.be/xtPcJhWE2H8' controls={true} /></div>
                                <div className={style.video} ><ReactPlayer url='https://youtu.be/UXbwNmjvE_w' controls={true} /></div>
                                <div className={style.video} ><ReactPlayer url='https://youtu.be/B5787X__AX4' controls={true} /></div>
                                <div className={style.video} ><ReactPlayer url='https://youtu.be/iCyVwWlOWgc' controls={true} /></div>
                                <div className={style.video} ><ReactPlayer url='https://youtu.be/-_HAXpSSkU4' controls={true} /></div>
                                <div className={style.video} ><ReactPlayer url='https://youtu.be/shE6Mfs3A2k' controls={true} /></div>
                                <div className={style.video} ><ReactPlayer url='https://youtu.be/7qrTg_IoTEo' controls={true} /></div>
                                <div className={style.video} ><ReactPlayer url='https://youtu.be/iVx3aFPmm3k' controls={true} /></div>
                                <div className={style.video} ><ReactPlayer url='https://youtu.be/iio1IU-tH5o' controls={true} /></div>*/}
                                
                                {this.state.videos.map((video: any, i: number) => (
                                    <div className={style.video} key={i}>
                                        <div
                                            onClick={(e: any) => {
                                                video.visible = !video.visible;
                                                this.setState({
                                                    visibleVideoModal: true,
                                                    currentVideo: video
                                                })
                                            }}
                                        >
                                            <div className={style.videoTitle}>{video.name}</div>
                                            <img src={video.screenshot} />
                                        </div>
                                    </div>
                                ))}
                            </Carousel>
                        </div>
                    </article>
                {/*</Element>*/}
                {/*<div id="reviews"></div>*/}
                    <Modal
                        visible={this.state.visibleVideoModal}
                        onOk={this.handleCancel}
                        onCancel={this.handleCancel}
                        className={style.videoModal}
                        destroyOnClose={true}
                    >
                        <div className={style.videoModalTitle}>{this.state.currentVideo ? this.state.currentVideo.name : ''}</div>
                        {this.state.currentVideo ?
                            (<video controls>
                                <source src={this.state.currentVideo.path} type="video/mp4" />
                            </video>) : 
                            (<div></div>)
                        }
                    </Modal>
            </section>
        );
    }
}