import * as React from 'react';

import style from './style.module.css';

interface SendTypeForm {}

export class DataAgreement extends React.Component<SendTypeForm, any> {
  constructor(props: SendTypeForm) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <section className={style.dataAgreement}>
        <h2>Соглашение о передаче данных</h2>
        <p>
          Я даю согласие ООО МКК «КАПЕЛЛА» на обработку всех моих персональных данных, приведенных в заявке, и подтверждаю их принадлежность мне. Я даю согласие ООО МКК «КАПЕЛЛА», на обработку моих персональных данных любыми способами, в том числе третьими лицами, в том числе воспроизведение, электронное копирование, обезличивание, блокирование, уничтожение, а также вышеуказанную обработку иных моих персональных данных, полученных в результате их обработки, с целью:<br/>
          — создания информационных систем персональных данных ООО МКК «КАПЕЛЛА»<br/>
          — заключения договора займа с ООО МКК «КАПЕЛЛА»<br/>
          а также в любых других целях, прямо или косвенно связанных с предоставлением займа и предложением иных продуктов ООО МКК «КАПЕЛЛА» и его партнеров.<br/>
          <br/><br/>
          Я даю согласие ООО МКК «КАПЕЛЛА» на передачу персональных данных лицам, являющихся партнерами ООО МКК «КАПЕЛЛА» в том числе платежным системам, расчетным небанковским кредитным организациям, коллекторским агентствам и обработку персональных данных такими партнерами с целью выдачи займа, предоставления электронных средств платежа и исполнения обязательств по договору займа.
          <br/><br/>
          Указанное согласие дано на срок 15 лет, а в случае его отзыва обработка моих персональных данных должна быть прекращена ООО МКК «КАПЕЛЛА» и/или третьими лицами и данные уничтожены при условии расторжения договора займа и полного погашения задолженности по договору займа в срок не позднее 1 (одного) года с даты прекращения действия договора займа. Отзыв указанного согласия осуществляется путем подачи в ООО МКК «КАПЕЛЛА» письменного заявления.
          <br/><br/>
          Я даю свое согласие ООО МКК «КАПЕЛЛА» на осуществление запроса о предоставлении из бюро кредитных историй (БКИ) и центрального каталога кредитных историй (ЦККИ) информации о моей кредитной историй в соответствии с Федеральным законом от 30.12.2004 № 218-ФЗ «О кредитных историях».
          <br/><br/>
          Я даю согласие на предоставление ООО МКК «КАПЕЛЛА» всей имеющейся обо мне информации в объеме, в порядке и на условиях, определенных Федеральным законом «О кредитных историях» № 218-ФЗ от 30 декабря 2004 года, во все бюро кредитных историй, включенные в государственный реестр бюро кредитных историй.
          <br/><br/>
          Я даю согласие ООО МКК «КАПЕЛЛА» на получение рекламных и информационных сообщений на указанный выше контактный номер, подтверждаю его принадлежность мне, а также даю согласие на обработку своих персональных данных в целях продвижения услуг ООО МКК «КАПЕЛЛА» в том числе с использованием информационно-телекоммуникационных сетей.
        </p>
      </section>
    );
  }
}
