import * as React from 'react';

import { WrappedCustomLoanForm as LoanForm } from 'app/components/Index/Loan/LoanForm';

import style from './style.module.css';

export interface Loan {
    showForm: any;

}

export class Loan extends React.Component<Loan> {
  static defaultProps: Partial<Loan> = {};

  render() {
    return (
      <section className={style.loan}>
        <article>
          <h1>Автозаймы честно и просто</h1>
          <p>
            Creditcar – современная компания, которая может предложить займ быстро и безопасно,
            честно и просто.
            <br />
            <br />
            Альтернатива, при которой автомобиль остается в собственности и пользовании владельца.{' '}
            <br />
            <br />
            Вы оставляете только ПТС на время пользования займа.
          </p>
        </article>
        <LoanForm showForm={this.props.showForm} />
      </section>
    );
  }
}
