import * as React from 'react';
import { Modal } from 'antd';
import classNames from 'classnames';

import { WrappedCustomSimpleForm as SimpleForm } from 'app/components/SimpleForm';

import pts from 'assets/img/pledge_PTS.png';
import car from 'assets/img/car.png';
import moto from 'assets/img/moto.png';
import wather from 'assets/img/wather.png';
import spec from 'assets/img/spec.png';
import img1 from 'assets/icons/index/pocket_feature.png';
import img2 from 'assets/icons/index/clock_feature.png';
import img3 from 'assets/img/document_feature.png';
import img4 from 'assets/img/paper_feature.png';
import img5 from 'assets/img/phone_feature.png';
import img6 from 'assets/icons/index/good_feature.png';
import garantIcon from 'assets/img/garantIcon.png';

import style from './style.module.css';
import {Button} from "../../components/Button";


export interface Services {
    visibleSendForm: any;
}


export class Services extends React.Component<Services, any> {
  static defaultProps: Partial<Services> = {
    visibleSendForm: false
  };

  constructor(props: Services, context?: any) {
    super(props, context);
    this.state = {
      visibleSendForm: false
    };
  }

  items = [
    {
      img: pts,
      label: 'ПТС'
    },
    {
      img: car,
      label: 'Автомобиля'
    },
    {
      img: moto,
      label: 'Мототехники'
    },
    {
      img: wather,
      label: 'Водной техники'
    },
    {
      img: spec,
      label: 'Спецтехники'
    }
  ];

  features = [
    {
      image: img1,
      text: (
        <span>
          До 500 000 &#8381;
          <br />
          на срок до 1 года
        </span>
      )
    },
    {
      image: img2,
      text: (
        <span>
          Оформление
          <br />и выдача 60 минут
        </span>
      )
    },
    {
      image: img3,
      text: (
        <span>
          Всего три документа
          <br />
          Паспорт РФ, СТС, ПТС
        </span>
      )
    },
    {
      image: img4,
      text: (
        <span>
          Без поручителей
          <br />и справок о доходах
        </span>
      )
    },
    {
      image: img5,
      text: (
        <span>
          Твердое 100%
          <br />
          одобрение по телефону
        </span>
      )
    },
    {
      image: img6,
      text: (
        <span>
          Вы оставляете
          <br />
          автомобиль у себя
        </span>
      )
    }
  ];

  garanties1 = [
    'Дистанционная проверка автомобиля',
    'Бесплатное оформление договора займа',
    'Бесплатное продление договора займа',
    'Одобрение заявки дистанционно',
    'Условия в офисе соответствуют заявленным по телефону'
  ];

  garanties2 = [
    'Договор займа строго в соответствии с законом РФ',
    'Состоим в реестре ЦБ РФ',
    'Залог под иностранные и отечественные автомобили',
    'Бесплатная оценка автомобиля',
    'Наличные сразу при подписании договора'
  ];

  wrapperHandlerModalFunc = (value: boolean) => {
    this.setState({
      visibleSendForm: true
    });
  };

  handleCancel = (e: any) => {
    console.log(e);
    this.setState({
      visibleSendForm: false
    });
  };

  render() {
    console.log(this.props);
    return (
      <div className={style.services}>
        <section className={style.pledge}>
          {this.items.map(({ img }, i) => (
            <article key={i}>
              <img src={img} alt="" />
            </article>
          ))}
        </section>
        <h1>Займы под залог ПТС</h1>
        <section className={style.features}>
          {this.features.map(({ image, text }, i) => (
            <article key={i}>
              <div>
                <img src={image} alt="" />
              </div>
              {text}
            </article>
          ))}
        </section>

        <section className={style.lists}>
          <ul>
            {this.garanties1.map((text, i) => (
              <li key={i}>
                <img src={garantIcon} alt="" />
                <span>{text}</span>
              </li>
            ))}
          </ul>
          <ul>
            {this.garanties2.map((text, i) => (
              <li key={i}>
                <img src={garantIcon} alt="" />
                <span>{text}</span>
              </li>
            ))}
          </ul>
        </section>
        <Button
            className={style.submitButton}
            onClick={() => this.wrapperHandlerModalFunc(false)}
            styleName={'old'}>
          ВЗЯТЬ ЗАЙМ
        </Button>
        <Modal
          visible={this.state.visibleSendForm}
          onOk={this.handleCancel}
          onCancel={this.handleCancel}
          className={classNames(style.modalSendForm, style.modalSimpleForm)}
        >
          <SimpleForm onCancel={this.handleCancel} showSum={true} />
        </Modal>
      </div>
    );
  }
}
