import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import style from './style.module.css';
import pts from 'assets/img/pledge_PTS.png';
import car from 'assets/img/car.png';
import moto from 'assets/img/moto.png';
import wather from 'assets/img/wather.png';
import spec from 'assets/img/spec.png';

export interface Pledge extends RouteComponentProps<void> {
}

export class Pledge extends React.Component<Pledge> {
  static defaultProps: Partial<Pledge> = {};

  items = [
    {
      img: pts,
      label: 'ПТС'
    },
    {
      img: car,
      label: 'Автомобиля'
    },
    {
      img: moto,
      label: 'Мототехники'
    },
    {
      img: wather,
      label: 'Водной техники'
    },
    {
      img: spec,
      label: 'Спецтехники'
    }
  ];

  render() {
    return (
      <section className={style.pledge}>
        <h1>Деньги под залог</h1>
        <section>
          {this.items.map(({ img, label }, i) => (
            <article key={i}>
              <img src={img} alt="" />
              <div>{label}</div>
            </article>
          ))}
        </section>
      </section>
    );
  }
}
