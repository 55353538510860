import * as React from 'react';

import {Button} from "../../components/Button";
import {Link} from 'react-router-dom';
import {Input, Modal, Icon} from 'antd';
import Form, { FormComponentProps } from 'antd/lib/form';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import ru from 'date-fns/locale/ru';

import style from './style.module.css';
import axios from 'axios';

export interface CardsList {
}

const FormItem = Form.Item;

export class CardsList extends React.Component<CardsList, any> {
  static defaultProps: Partial<CardsList> = {};

  constructor(props: CardsList, context?: any) {
    super(props, context);
    this.state = {
      login: null,
      password: null,
      token: null,
      error: false,
      cards: [],
      interval: null,
      timeoutTracker: null,
    };
  }

  componentDidMount(): void {
    registerLocale('ru', ru);
    let token = this.getToken();
    this.setState({token: token});
    if (token !== null) {
      this.setExpiredTime();
      window.addEventListener("mousemove", this.updateExpiredTime);
      window.addEventListener("scroll", this.updateExpiredTime);
      window.addEventListener("keydown", this.updateExpiredTime);
      this.startInterval();
    }
    axios.get('https://credit-car.ru/api/tinkoff/cards').then((response) => {
      // console.log(response);
      this.setState({cards: response.data.cards});
    });
  }

  adminUser = {login: 'dircreditcar', password: 'rhtlbnrfh1987'};
  loginError = false;

  setToken = (userToken: any) => {
    sessionStorage.setItem('tokenCards', JSON.stringify(userToken));
    this.setState({token: userToken.token});
  };

  setExpiredTime = () => {
    sessionStorage.setItem('_expiredTimeCards', '' + (Date.now() + 3 * 60 * 1000));
  };

  updateExpiredTime = () => {
    if (this.state.timeoutTracker) {
      clearTimeout(this.state.timeoutTracker);
    }
    let timeoutTracker = setTimeout(() => {
      sessionStorage.setItem("_expiredTimeCards", '' + (Date.now() + 3 * 60 * 1000));
    }, 300);
    this.setState({timeoutTracker: timeoutTracker});
  }

  startInterval = () => {
    // this.updateExpiredTime();

    let interval = setInterval(() => {
      const expiredTime = parseInt(sessionStorage.getItem("_expiredTimeCards") || '0');
      // console.log(Date.now());
      // console.log(expiredTime);
      // console.log(this.state.token);
      if (expiredTime < Date.now()) {
        console.log('time expired');
        this.cleanUp();
      }
      // console.log('--------------');
    }, 1000);
    this.setState({interval: interval});
  };

  cleanUp = () => {
    sessionStorage.removeItem("_expiredTimeCards");
    clearInterval(this.state.interval);
    this.setToken({token: null});
    window.removeEventListener("mousemove", this.updateExpiredTime);
    window.removeEventListener("scroll", this.updateExpiredTime);
    window.removeEventListener("keydown", this.updateExpiredTime);
  }

  getToken = () => {
    const tokenString = sessionStorage.getItem('tokenCards');
    if (tokenString) {
      const userToken = JSON.parse(tokenString);
      return userToken.token;
    }
    else return null;
  };

  adminLogin = () => {
    this.setState({error: false})
    if (this.state.login == this.adminUser.login && this.state.password == this.adminUser.password) {
      this.setToken({token: 'asdfgh345bnm'});
      this.setExpiredTime();
      window.addEventListener("mousemove", this.updateExpiredTime);
      window.addEventListener("scroll", this.updateExpiredTime);
      window.addEventListener("keydown", this.updateExpiredTime);
      this.startInterval();
    }
    else {
      this.setState({error: true})
    }
  };

  setSum = (index: number, sum: any) => {
    let cards = this.state.cards;
    cards[index].sum = sum;
    this.setState({cards: cards});
  };

  setContractNum = (index: number, contract_num: any) => {
    let cards = this.state.cards;
    cards[index].contract_num = contract_num;
    this.setState({cards: cards});
  };

  setContractDate = (date: any, index: number) => {
    let cards = this.state.cards;
    cards[index].contract_date = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
    this.setState({cards: cards});
  };

  getDateObject = (date: string) => {
    let date_array = date.split('.');
    return new Date(Number(date_array[2]), Number(date_array[1]) - 1, Number(date_array[0]));
  };

  confirmCard = (event: any) => {
    let index = event.target.attributes.card_index.nodeValue;
    if (this.state.cards[index].sum == null || this.state.cards[index].sum == 0) {
      alert('Некорректная сумма!');
      return;
    }
    axios.post('https://credit-car.ru/api/tinkoff/card/confirm', {
      card_id: this.state.cards[index].id, 
      sum: this.state.cards[index].sum,
      contract_num: this.state.cards[index].contract_num,
      contract_date: this.state.cards[index].contract_date
    }).then((response) => {
      console.log(response);
      if (response.data.success) alert(response.data.message);
    });
  };

  render() {
    if (!this.state.token) {
      return (
        <div className={style.cardsLogin}>
          <h2>Вход</h2>
          {this.state.error ?
            <p className={style.loginError}>Неверный логин или пароль!</p>
            : ''}
          <Form>
            <div className={style.formRow}>
              <label htmlFor="login">Логин</label>
              <input type="login" id="login" onChange={e => this.setState({login: e.target.value})} />
            </div>
            <div className={style.formRow}>
              <label htmlFor="password">Пароль</label>
              <input type="password" id="password" onChange={e => this.setState({password: e.target.value})} />
            </div>
            <Button
              className={style.loginButton}
              onClick={this.adminLogin}
              styleName={'old'}>
              Войти
            </Button>
          </Form>
        </div>
      )
    }
    else {
      // console.log(this.state.cards)
      return (
        <div className={style.cardsList}>
          <table>
            <thead>
              <tr>
                <th>№</th>
                <th>Номер Карты</th>
                <th>Срок Действия</th>
                <th>Имя</th>
                <th>Фамилия</th>
                <th>Сумма</th>
                <th>№ Договора</th>
                <th>Дата Договора</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {this.state.cards.map((card: any, i: number) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{card.card_number}</td>
                  <td>{card.expire_date}</td>
                  <td>{card.first_name}</td>
                  <td>{card.last_name}</td>
                  <td>
                    <input 
                      type="number" 
                      value={card.sum !== null ? card.sum : 0} 
                      onChange={e => this.setSum(i, e.target.value)} 
                    />
                  </td>
                  <td>
                    <input 
                      type="text" 
                      value={card.contract_num !== null ? card.contract_num : ''} 
                      onChange={e => this.setContractNum(i, e.target.value)} 
                    />
                  </td>
                  <td>
                    <DatePicker 
                      locale="ru"
                      dateFormat="dd.MM.yyyy"
                      selected={card.contract_date !== null ? this.getDateObject(card.contract_date) : null} 
                      onChange={(date) => this.setContractDate(date, i)} 
                    />
                  </td>
                  <td>
                    <Button
                      key={i}
                      card_index={i}
                      onClick={this.confirmCard}
                    >
                      Подтвердить
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
  }
}
