import * as React from 'react';

import {Button} from "../../components/Button";
import {Link} from 'react-router-dom'
import style from './style.module.css';

export interface VerifyFail {
}

export class VerifyFail extends React.Component<VerifyFail, any> {
  static defaultProps: Partial<VerifyFail> = {};

  constructor(props: VerifyFail, context?: any) {
    super(props, context);
    this.state = {};
  }

  render() {
    return (
      <div className={style.verify}>
        <h1>Произошла ошибка при верификации карты!</h1>
        <Link to='/' className={style.returnLink}>Вернуться на главную</Link>
      </div>
    );
  }
}
