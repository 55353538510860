import * as React from 'react';
import * as currencyFormatter from 'currency-formatter';
import {InputNumber, Modal, Slider} from 'antd';
import Form, { FormComponentProps } from 'antd/lib/form';

import man from 'assets/icons/index/man.png';
import car from 'assets/icons/index/carbg.png';
import cityLeft from 'assets/icons/index/cityleft.gif';
import cityRight from 'assets/icons/index/cityright.gif';
import action from 'assets/img/action.png';

import manWebp from 'assets/icons/index/webp/man.webp';
import carWebp from 'assets/icons/index/webp/carbg.webp';
import cityLeftWebp from 'assets/icons/index/webp/cityleft.webp';
import cityRightWebp from 'assets/icons/index/webp/cityright.webp';
import actionWebp from 'assets/img/webp/action.webp';
import style from './style.module.css';

import { Button } from 'app/components/Button'
import {WrappedCustomSendForm as SendForm} from "../../SendForm";

interface LoanTypeForm extends FormComponentProps {
  showForm?: any;
}

const FormItem = Form.Item;

class LoanForm extends React.Component<LoanTypeForm, { scale: any; badSum: boolean }> {
  [key: string]: any;
  static defaultProps: Partial<LoanTypeForm & { [key: string]: any }> = {
    showForm: () => null
  };

  state = {
    //scale: 11.111,
    scale: 5.2632,
    badSum: false
  };

  handleSubmit = () => {
    this.props.form.validateFields((err: object, values: object) => {
      if (!err) {
        console.log(values);
      }
    });
  };

  sliderChange = (val: any) => {
    console.log('step sum', val);
    this.props.form.setFieldsValue({
      sum: this.roundOffSum((val * 950000) / 100 + 50000, 10000)
    });
    this.setState({
      scale: val
    });
    this.changeBorderColor('sum', '#4bc0b0');
    //this.handleChange()
  };

  numberChange = (val: any) => {
    console.log(
      'Number change',
      currencyFormatter.format(val, {
        thousand: '',
        precision: 0,
        decimal: ''
      })
    );
    let numberVal: any = currencyFormatter.format(val, {
      thousand: '',
      precision: 0,
      decimal: ''
    });
    if (numberVal > 1000000) {
      console.log('1');
      this.setState({
        scale: 100,
        badSum: true
      });
      this.props.form.setFieldsValue({
        sum: 1000000
      });
    } else if (numberVal <= 50000) {
      console.log('2', numberVal, this.roundOffSum(numberVal));
      this.setState({
        scale: 0,
        badSum: false
      });
    } else {
      this.setState({
        scale: Math.round(((numberVal - 50000) * 100) / 950000),
        badSum: false
      });
      console.log(
          'Sacle change', {
        scale: Math.round(((numberVal - 50000) * 100) / 950000),
        badSum: false
      });
      this.changeBorderColor('sum', '#4bc0b0');
    }
    //this.handleChange()
  };

  changeBorderColor(id: any, color: string) {
    let item = document.getElementById(id) || { style: { borderColor: '' } };
    item.style.borderColor = color;
  }

  roundOffSum = (sum: number, step = 10000) => {
    if (sum < 10000) sum = 10000;
    console.log(sum, step, Math.round(sum / step) * 10000);
    return Math.round(sum / step) * 10000;
  };

  static handleFullSum = (sum: any) => {
    console.log('HANDLE SUM', sum);
    if (+sum < 50000) {
      sum = 50000;
    }
    return currencyFormatter.format(Math.floor((sum * 3.5) / 100), {
      code: 'RUB',
      precision: 0
    });
  };

  render() {
    const {
      form: { getFieldDecorator }
    } = this.props;

    const { scale } = this.state;
    interface LooseObject {
      [key: string]: any;
    }

    let marks: LooseObject = {
      0: {
        style: {
          color: 'white',
          marginLeft: '0px',
          whiteSpace: 'nowrap'
        },
        label: (
          <div>
            {currencyFormatter.format(50000, {
              code: 'RUB',
              precision: 0
            })}
          </div>
        )
      }
    };
    let price = 0;
    while (price < 100) {
      price += 10;
      marks[price] = '';
    }
    marks[100] = {
      style: {
        color: 'white',
        marginLeft: '-60px',
        whiteSpace: 'nowrap'
      },
      label: (
        <div>
          {currencyFormatter.format(1000000, {
            code: 'RUB',
            precision: 0
          })}
        </div>
      )
    };

    return (
      <article className={style.loanForm}>
        <picture className={style.cityRight} >
          <source type={"image/webp"}
                  srcSet={`${cityRightWebp}`}/>
          <img src={cityRight} alt="" />
        </picture>
        <picture className={style.cityLeft}>
          <source type={"image/webp"}
                  srcSet={`${cityLeftWebp}`}/>
          <img src={cityLeft} alt="" />
        </picture>
        <picture className={style.car}>
          <source type={"image/webp"}
                  srcSet={`${carWebp}`}/>
          <img  src={car} alt="" />
        </picture>
        <picture className={style.man}>
          <source type={"image/webp"}
                  srcSet={`${manWebp}`}/>
          <img  src={man} alt="" />
        </picture>
        {/*<picture className={style.action}>*/}
        {/*  <source type={"image/webp"}*/}
        {/*          srcSet={`${actionWebp}`}/>*/}
        {/*  <img  src={action} alt="акция" />*/}
        {/*</picture>*/}

        <div className={style.formWrapper}>
          <div className={style.gradientForm}>
            { this.state.scale > 46.9999 &&
            <div className={style.serviceBy}>Услуга предоставляется ООО МКК "Капелла" и ООО МКК "Народная опора"</div>
            }
          </div>
          <Form className={style.form} onSubmit={this.handleSubmit}>
            <h3>Сумма займа под ПТС:</h3>
            <div>
              <FormItem>
                {getFieldDecorator('sum', {
                  initialValue: (scale * 950000) / 100 + 50000,
                  rules: [
                    {
                      required: true,
                      message: ' '
                    }
                  ]
                })(
                  <InputNumber
                    className={style.sum}
                    onChange={this.numberChange}
                    formatter={(value: any) => {
                      value = currencyFormatter.format(value, {
                        thousand: '',
                        precision: 0,
                        decimal: ''
                      });

                      if (!value && +value !== 0) {
                        console.log('typeof string!');
                        return currencyFormatter.format((scale * 950000) / 100 + 50000, {
                          thousand: ' ',
                          precision: 0,
                          decimal: ''
                        });
                      }

                      if (value > 1000000)
                        return currencyFormatter.format(1000000, {
                          thousand: ' ',
                          precision: 0,
                          decimal: ''
                        });

                      return currencyFormatter.format(value, {
                        thousand: ' ',
                        precision: 0,
                        decimal: ''
                      });
                    }}
                    parser={(value: any) => {
                      console.log('parser', value);
                      let newValue = +value.replace(/[^0-9]/g, '');
                      if (newValue > 1000000) {
                        return 1000000;
                      }
                      return value.replace(/[^0-9]/g, '');
                    }}
                    onBlur={() => {
                      if (this.props.form.getFieldValue('sum') < 50000) {
                        this.props.form.setFieldsValue({ sum: 50000 });
                      } else {
                        this.props.form.setFieldsValue({
                          sum: this.roundOffSum(this.props.form.getFieldValue('sum'), 10000)
                        });
                      }
                    }}
                  />
                )}
              </FormItem>
              <span className={style.iconRuble}>
                {currencyFormatter.findCurrency('RUB').symbol}
              </span>
            </div>
            <div className={style.slider}>
              <Slider
                marks={marks}
                onChange={this.sliderChange}
                tipFormatter={null}
                step={0.2}
                defaultValue={20}
                value={scale}
              />
            </div>
            <div className={style.procentPayment}>
              <span>
                Ежемесячный платеж от:{' '}
                <b>{LoanForm.handleFullSum(this.props.form.getFieldValue('sum'))}</b>
              </span>
            </div>
            <div className={style.expPayment}>Досрочное погашение без комиссий и штрафных санкций</div>
            <Button
              className={style.submitButton}
              onClick={(e: any) => {
                e.preventDefault();
                this.props.showForm('SimpleForm', false)
                // this.props.showForm('SendForm', false)
              }}
              styleName={'old'}
            >
              ПОЛУЧИТЬ ТОЧНЫЙ РАСЧЕТ
            </Button>
            <div className={style.expPayment2}>*пример расчета носит исключительно информационный характер и не является публичной офертой</div>
          </Form>
        </div>
      </article>
    );
  }
}

export const WrappedCustomLoanForm = Form.create()(LoanForm);
