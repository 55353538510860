import * as React from 'react';
import {InputNumber, Input, Modal, Icon} from 'antd';
import Form, { FormComponentProps } from 'antd/lib/form';
import fileIcon from 'assets/img/fileIcon.png';
import ym from 'react-yandex-metrika';
import { isFirefox, isMobile, isMobileOnly } from 'react-device-detect';
import axios from 'axios'
import classNames from 'classnames'

import { FriendSuccess } from 'app/components/Index/FriendSuccess';
import style from './style.module.css';
import { formatName, formatNumber, parseNumber } from 'app/utils';
import {Button} from "../Button";
import {DataAgreement} from "../Index/DataAgreement";

interface SendTypeForm extends FormComponentProps {
  onCancel: any;
  onVisibleChange: any;
  formMetricId: any;
}

const FormItem = Form.Item;

class FriendForm extends React.Component<SendTypeForm, any> {
  static defaultProps: Partial<SendTypeForm> = {
    onCancel: () => null,
    onVisibleChange: () => null,
    formMetricId: 'friend-form'
  };

  constructor(props: SendTypeForm) {
    super(props);
    this.state = {
      success: false,
      click: true
    };
  }

  handleSubmit = () => {
    // console.log('handleSubmit');
    this.props.form.validateFields((err: object, { name, number, friendNumber, friendName }) => {
      let numberIsValid = number ? number.toString().length === 10 : false;
      let friendNumberIsValid = friendNumber ? friendNumber.toString().length === 10 : false;
      let formMetricId = this.props.formMetricId;

      if (!err && numberIsValid && name && friendNumberIsValid && friendName) {
        this.setState({
          loading: true
        });
        axios.post('https://creditcar.org' + '/api/send-friend-email', { isaction: true, phonenumber: number, name: name, friendName: friendName, friendNumber: friendNumber }).then((response) => {
          let {
            error,
            success = true
          } = response.data;
          console.log(response.data);
          if (error) {
            this.setState({
              success: false,
              loading: false
            });
          } else if (success) {
            ym('reachGoal', formMetricId);
            this.setState({
              loading: false,
              success: true,
            });
            this.props.onVisibleChange();
            this.handleShow();
          }
        });
        setTimeout(() => {
        
        }, 5000)
        this.setState({
          loading: false,
          success: true,
        });
        this.props.onVisibleChange();
        this.handleShow();
      } else {
        if (!err || !number || !friendNumber) {
          /*if (!number || number.toString().length !== 10) this.changeBorderColor("number", "#e80000");*/
          return this.setState({
            error: true,
            message: 'Заполните обязательные поля'
          });
        }
      }
    });
  };

  handleNumberChange = (number: any, name: any) => {
    if (!number || number.toString().length !== 10) {
      this.setState({
        ['validate' + name + 'NumberStatus']: 'error',
        error: false
      });
    } else {
      this.setState({
        ['validate' + name + 'NumberStatus']: 'success',
        error: false
      });
    }
  };

  changeBorderColor(id: any, color: string) {
    let item: any = document.getElementById(id);
    item.style.borderColor = color;
  }

  handleShow = () => {
    this.setState({
      visibleSuccess: true,
    });
  };


  handleCancel = () => {
    document.location.replace('/');
  };

  handleCancelDA = () => {
    this.setState({
      visibleSuccess: false,
      visibleDataAgreement: false
    });
  }

  wrapperHandlerModalFunc = (name: string, value: boolean) => {
    this.setState({
      ['visible' + name]: value
    });
  };

  radioClick = (clickName: string) => {
    this.setState({
      [clickName]: !this.state[clickName]
    });
  };
  render() {
    const {
      form: { getFieldDecorator }
    } = this.props;

    let numberClassName = ''; //style.numberItem;
    /* if (isFirefox || isMobile) {
      itemClassName = style.mozNameItem;
      numberClassName = style.mozNumberItem;
    }*/

    let { loading } = this.state;

  if (isMobileOnly)
    return(
      <section className={style.menu}>
        <Form>
          <div className={style.formRow}>
          <FormItem
              label={'Ваше ФИО*'}>
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: <br />
                },
                {
                  validator: (rule: any, value: any, callback: any) => {
                    let newVal = typeof value == 'undefined' ? value : formatName(value);
                    if (newVal != value) {
                      console.log([newVal, value])
                      this.props.form.setFieldsValue({
                        name: newVal
                      });
                    }
                    callback();
                  }
                }
              ]
            })(<Input placeholder={'Ваше ФИО*'} onChange={() => null} />)}
          </FormItem>
          </div>
          <div className={style.formRow}>
          <FormItem
            className={!this.state.focusNumber ? numberClassName : ''}
            validateStatus={this.state.validateNumberStatus}
            label={'Ваш телефон*'}
          >
            {getFieldDecorator('number', {
              rules: [
                {
                  required: true,
                  message: <br />
                }
              ]
            })(
              <InputNumber
                placeholder={!this.state.numberLabel ? 'Ваш телефон*' : ' '}
                max={9999999999}
                formatter={formatNumber}
                parser={parseNumber}
                onChange={(number: any) => {
                  this.handleNumberChange(number, '');
                }}
                onFocus={() => {
                  if (!this.props.form.getFieldValue('number')) {
                    this.props.form.setFieldsValue({
                      number: ' '
                    });
                  }
                  this.setState({
                    focusNumber: true
                  });
                }}
                onBlur={() => {
                  console.log(this.props.form.getFieldValue('number'));
                  if (this.props.form.getFieldValue('number') == ' ') {
                    this.props.form.setFieldsValue({
                      number: ''
                    });
                    this.setState({
                      focusNumber: false
                    });
                  }
                  if (!this.props.form.getFieldValue('number')) {
                    this.setState({
                      focusNumber: false
                    });
                  }
                }}
              />
            )}
          </FormItem>
          </div>
          <div className={style.formRow}>
            <FormItem
                label={'Имя друга*'}>
              {getFieldDecorator('friendName', {
                rules: [
                  {
                    required: true,
                    message: <br />
                  },
                  {
                    validator: (rule: any, value: any, callback: any) => {
                      let newVal = typeof value == 'undefined' ? value : formatName(value);
                      if (newVal != value) {
                        console.log([newVal, value])
                        this.props.form.setFieldsValue({
                          friendName: newVal
                        });
                      }
                      callback();
                    }
                  }
                ]
              })(<Input placeholder={'Имя друга*'} onChange={() => null} />)}
            </FormItem>
          </div>
          <div className={style.formRow}>
            <FormItem
                className={!this.state.focusFriendNumber ? numberClassName : ''}
                validateStatus={this.state.validateFriendNumberStatus}
                label={'Телефон друга*'}
            >
              {getFieldDecorator('friendNumber', {
                rules: [
                  {
                    required: true,
                    message: <br />
                  }
                ]
              })(
                  <InputNumber
                      placeholder={!this.state.friendNumberLabel ? 'Телефон друга*' : ' '}
                      max={9999999999}
                      formatter={formatNumber}
                      parser={parseNumber}
                      onChange={(number: any) => {
                        this.handleNumberChange(number, 'Friend');
                      }}
                      onFocus={() => {
                        if (!this.props.form.getFieldValue('friendNumber')) {
                          this.props.form.setFieldsValue({
                            friendNumber: ' '
                          });
                        }
                        this.setState({
                          focusFriendNumber: true
                        });
                      }}
                      onBlur={() => {
                        console.log(this.props.form.getFieldValue('friendNumber'));
                        if (this.props.form.getFieldValue('friendNumber') == ' ') {

                          this.props.form.setFieldsValue({
                            friendNumber: ''
                          });
                          this.setState({
                            focusFriendNumber: false
                          });
                        }
                        if (!this.props.form.getFieldValue('friendNumber')) {
                          this.setState({
                            focusFriendNumber: false
                          });
                        }
                      }}
                  />
              )}
            </FormItem>
          </div>
          <div className={style.formRow}>
            <input
                onClick={() => this.radioClick('click')}
                type={'checkbox'}
                checked={this.state.click}
                className={style.tickbox}
            />
            <span className={style.agreeText}>Я согласен (-на) <span  onClick={() => this.wrapperHandlerModalFunc('DataAgreement', true)} className={style.agreeLink}>с политикой обработки персональных данных</span></span>
          </div>
          <Button
            className={classNames(style.submitButton, this.state.loading ? ' disabled' : '')}
            onClick={this.handleSubmit}
            styleName={'old'}>
            {loading ? <Icon type="loading" /> : 'ОТПРАВИТЬ'}
          </Button>
        </Form>
        <Modal
            visible={this.state.visibleDataAgreement}
            onOk={this.handleCancelDA}
            onCancel={this.handleCancelDA}
            className={style.modalDataAgreement}
        >
          <DataAgreement />
        </Modal>
        <Modal
          visible={this.state.visibleSuccess}
          onOk={() => {
            this.handleCancel();
            this.props.onCancel();
          }}
          onCancel={() => {
            this.handleCancel();
            this.props.onCancel();
          }}
          className={style.modalSuccessForm}
          destroyOnClose={true}
        >
          <FriendSuccess closeForm={() => {
            this.handleCancel();
            this.props.onCancel();
          }} />
        </Modal>
      </section>
    );
  else
    return(
      <section className={style.menu}>
        <Form>
          <div className={style.formRow}>
          <FormItem
              label={'Ваше ФИО*'}>
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: <br />
                },
                {
                  validator: (rule: any, value: any, callback: any) => {
                    let newVal = typeof value == 'undefined' ? value : formatName(value);
                    if (newVal != value) {
                      console.log([newVal, value])
                      this.props.form.setFieldsValue({
                        name: newVal
                      });
                    }
                    callback();
                  }
                }
              ]
            })(<Input placeholder={'Ваше ФИО*'} onChange={() => null} />)}
          </FormItem>
          <FormItem
            className={!this.state.focusNumber ? numberClassName : ''}
            validateStatus={this.state.validateNumberStatus}
            label={'Ваш телефон*'}
          >
            {getFieldDecorator('number', {
              rules: [
                {
                  required: true,
                  message: <br />
                }
              ]
            })(
              <InputNumber
                placeholder={!this.state.numberLabel ? 'Ваш телефон*' : ' '}
                max={9999999999}
                formatter={formatNumber}
                parser={parseNumber}
                onChange={(number: any) => {
                  this.handleNumberChange(number, '');
                }}
                onFocus={() => {
                  if (!this.props.form.getFieldValue('number')) {
                    this.props.form.setFieldsValue({
                      number: ' '
                    });
                  }
                  this.setState({
                    focusNumber: true
                  });
                }}
                onBlur={() => {
                  console.log(this.props.form.getFieldValue('number'));
                  if (this.props.form.getFieldValue('number') == ' ') {
                    this.props.form.setFieldsValue({
                      number: ''
                    });
                    this.setState({
                      focusNumber: false
                    });
                  }
                  if (!this.props.form.getFieldValue('number')) {
                    this.setState({
                      focusNumber: false
                    });
                  }
                }}
              />
            )}
          </FormItem>
          </div>
          <div className={style.formRow}>
            <FormItem
                label={'Имя друга*'}>
              {getFieldDecorator('friendName', {
                rules: [
                  {
                    required: true,
                    message: <br />
                  },
                  {
                    validator: (rule: any, value: any, callback: any) => {
                      let newVal = typeof value == 'undefined' ? value : formatName(value);
                      if (newVal != value) {
                        console.log([newVal, value])
                        this.props.form.setFieldsValue({
                          friendName: newVal
                        });
                      }
                      callback();
                    }
                  }
                ]
              })(<Input placeholder={'Имя друга*'} onChange={() => null} />)}
            </FormItem>
            <FormItem
                className={!this.state.focusFriendNumber ? numberClassName : ''}
                validateStatus={this.state.validateFriendNumberStatus}
                label={'Телефон друга*'}
            >
              {getFieldDecorator('friendNumber', {
                rules: [
                  {
                    required: true,
                    message: <br />
                  }
                ]
              })(
                  <InputNumber
                      placeholder={!this.state.friendNumberLabel ? 'Телефон друга*' : ' '}
                      max={9999999999}
                      formatter={formatNumber}
                      parser={parseNumber}
                      onChange={(number: any) => {
                        this.handleNumberChange(number, 'Friend');
                      }}
                      onFocus={() => {
                        if (!this.props.form.getFieldValue('friendNumber')) {
                          this.props.form.setFieldsValue({
                            friendNumber: ' '
                          });
                        }
                        this.setState({
                          focusFriendNumber: true
                        });
                      }}
                      onBlur={() => {
                        console.log(this.props.form.getFieldValue('friendNumber'));
                        if (this.props.form.getFieldValue('friendNumber') == ' ') {

                          this.props.form.setFieldsValue({
                            friendNumber: ''
                          });
                          this.setState({
                            focusFriendNumber: false
                          });
                        }
                        if (!this.props.form.getFieldValue('friendNumber')) {
                          this.setState({
                            focusFriendNumber: false
                          });
                        }
                      }}
                  />
              )}
            </FormItem>
          </div>
          <div className={style.formRow}>
            <input
                onClick={() => this.radioClick('click')}
                type={'checkbox'}
                checked={this.state.click}
                className={style.tickbox}
            />
            <span className={style.agreeText}>Я согласен (-на) <span  onClick={() => this.wrapperHandlerModalFunc('DataAgreement', true)} className={style.agreeLink}>с политикой обработки персональных данных</span></span>
          </div>
          <Button
            className={classNames(style.submitButton, this.state.loading ? ' disabled' : '')}
            onClick={this.handleSubmit}
            styleName={'old'}>
            {loading ? <Icon type="loading" /> : 'ОТПРАВИТЬ'}
          </Button>
        </Form>
        <Modal
            visible={this.state.visibleDataAgreement}
            onOk={this.handleCancelDA}
            onCancel={this.handleCancelDA}
            className={style.modalDataAgreement}
        >
          <DataAgreement />
        </Modal>
        <Modal
          visible={this.state.visibleSuccess}
          onOk={() => {
            this.handleCancel();
            this.props.onCancel();
          }}
          onCancel={() => {
            this.handleCancel();
            this.props.onCancel();
          }}
          className={style.modalSuccessForm}
          destroyOnClose={true}
        >
          <FriendSuccess closeForm={() => {
            this.handleCancel();
            this.props.onCancel();
          }} />
        </Modal>
      </section>
    );
  }
}

export const WrappedCustomFriendForm = Form.create()(FriendForm);
