import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Modal } from 'antd';
import classNames from 'classnames';

import { Loan } from 'app/components/Index/Loan';
import { Features } from 'app/components/Index/Features';
import { Steps } from 'app/components/Index/Steps';
import { Testimonials } from 'app/components/Index/Testimonials';
import { Pledge } from 'app/components/Index/Pledge';
import { Owner } from 'app/components/Index/Owner';
import { Questions } from 'app/components/Index/Questions';
import { Info } from 'app/components/Index/Info';
// import { Examples } from 'app/components/Index/Examples';
import { WrappedCustomSendForm as SendForm } from 'app/components/SendForm';
import { WrappedCustomSimpleForm as SimpleForm } from 'app/components/SimpleForm';

import style from './style.module.css';
import Map from 'app/components/Index/Contacts';
import $ from 'jquery';


export interface App extends RouteComponentProps<void>{
    visibleSendForm: any;
}

export class App extends React.Component<App, any> {
  static defaultProps: Partial<App> = {
    visibleSendForm: false
  };

  constructor(props: App, context?: any) {
    super(props, context);
    this.state = {
      visibleSendForm: false,
      visibleSimpleForm: false
    };
  }

  componentDidMount(): void {
    // console.log(window.location.href);
    if (window.location.href.includes('reviews')) {
      // console.log('reviews!!!!');
      $(document).ready(function() {
        const el = document.getElementById('reviews')
        if (el) {    
          el.scrollIntoView({behavior: "smooth"})
        }
      });
    }
  }

  handleCancel = (e: any) => {
    console.log(e);
    this.setState({
      visibleSendForm: false,
      visibleSimpleForm: false,
      simpleType: false
    });
  };

  wrapperHandlerModalFunc = (name: string, value = false) => {
    console.log('visible' + name);
    this.setState({
      ['visible' + name]: true,
      simpleType: value
    });
  };

  render() {
    return (
      <div className={style.app}>
        <Loan showForm={this.wrapperHandlerModalFunc.bind(this)} />
        <Features />
        <Steps showForm={this.wrapperHandlerModalFunc.bind(this)} />
        <Testimonials />
        <Pledge />
        <Owner />
        <Questions />
        {/*<Examples />*/}
        <Map />
        {/*<Examples isAuction={true} />*/}
        <Info />
        <Modal
          visible={this.state.visibleSendForm}
          onOk={this.handleCancel}
          onCancel={this.handleCancel}
          className={style.modalSendForm}
          destroyOnClose={true}
        >
          <SendForm onCancel={this.handleCancel} showSum={this.state.simpleType} />
        </Modal>
        <Modal
          visible={this.state.visibleSimpleForm}
          onOk={this.handleCancel}
          onCancel={this.handleCancel}
          className={classNames(style.modalSendForm, style.modalSimpleForm)}
          destroyOnClose={true}
        >
          <SimpleForm onCancel={this.handleCancel} />
        </Modal>
      </div>
    );
  }
}
