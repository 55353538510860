import * as React from 'react';
interface PaymentForm {
    action: any,
    fields: {
        [key: string]: any
    },

}


class RequestForm extends React.Component<PaymentForm, any> {
    [key: string]: any
    static defaultProps: Partial<PaymentForm> = {
        action: '',
        fields: {}
    };

    constructor(props: PaymentForm) {
        super(props);

        let inputs = [];
        for (const key in  this.props.fields) {
            if ( this.props.fields.hasOwnProperty(key)) {
                inputs.push(<input key={key} name={key} type="hidden" defaultValue={this.props.fields[key]}/>)
            }
        }

        this.state = {
            inputs,
        };

        this.submitRef = React.createRef();
    }

    componentDidMount(): void {
        console.log(this.submitRef.current);
        this.submitRef.current.click()
    }


    render() {
        const {
            action,
        } = this.props;

        return (<form action={action} style={{
            position: 'absolute',
            visibility: 'hidden'
        }}>
            {this.state.inputs.map((input: any) => input)}
            <button ref={this.submitRef} type={'submit'} >Отправить</button>
        </form>);
    }
}

export {
    RequestForm
};

