import { handleActions } from 'redux-actions';
import { UserState } from './state';
import { Type } from 'app/actions/user';
import { UserModel } from 'app/models';

const initialState: UserState = {
  id: 1,
  full_name: '',
  auth: false,
  email: 'test@mail.com'
};

export const userReducer = handleActions<UserState, UserModel>(
  {
    [Type.AUTH_USER]: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          auth: action.payload.auth
        };
      }
      return state;
    },
    [Type.DELETE_USER]: (state, action) => {
      return state;
    },
    [Type.EDIT_USER]: (state, action) => {
      return state;
    }
  },
  initialState
);
