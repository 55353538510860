import * as React from 'react';
import {InputNumber, Input, Modal, Icon} from 'antd';
import Form, { FormComponentProps } from 'antd/lib/form';
import ym from 'react-yandex-metrika';
//import { isFirefox, isMobile } from 'react-device-detect';
import axios from 'axios'
import classNames from 'classnames'

import { Success } from 'app/components/Index/Success';
import style from './style.module.css';
import { formatName, formatNumber, parseNumber } from 'app/utils';
import {Button} from "../Button";

interface SendTypeForm extends FormComponentProps {
  onCancel: any;
  onVisibleChange: any;
  showSum: any;
  formMetricId: any;
}

const FormItem = Form.Item;

class SimpleForm extends React.Component<SendTypeForm, any> {
  static defaultProps: Partial<SendTypeForm> = {
    onCancel: () => null,
    showSum: false,
    onVisibleChange: () => null,
    formMetricId: 'min-form'
  };

  constructor(props: SendTypeForm) {
    super(props);
    this.state = {
      success: false
    };
  }

  handleSubmit = () => {
    this.props.form.validateFields((err: object, { name, number }) => {
      let numberIsValid = number ? number.toString().length === 10 : false;
      let formMetricId = this.props.formMetricId;

      if (!err && numberIsValid && name) {
        this.setState({
          loading: true
        });

        axios.post(window.location.origin + '/api/application', { phonenumber: number, name: name }).then((response) => {
          let {
            error,
            success = true
          } = response.data;
          if (error) {
            this.setState({
              success: false,
              loading: false
            });
          } else if (success) {
            ym('reachGoal', formMetricId);
            this.setState({
              loading: false,
              success: true,
            });
            this.props.onVisibleChange();
            this.handleShow();
          }
        });
        setTimeout(() => {

        }, 5000)
      } else {
        console.log('ERROR::', err, name, number);

        if (!err || !number) {
          /*if (!number || number.toString().length !== 10) this.changeBorderColor("number", "#e80000");*/
          return this.setState({
            error: true,
            message: 'Заполните обязательные поля'
          });
        }
      }
    });
  };

  handleNumberChange = (number: any) => {
    if (!number || number.toString().length !== 10) {
      this.setState({
        validateNumberStatus: 'error',
        error: false
      });
    } else {
      this.setState({
        validateNumberStatus: 'success',
        error: false
      });
    }
  };

  changeBorderColor(id: any, color: string) {
    let item: any = document.getElementById(id);
    item.style.borderColor = color;
  }

  handleShow = () => {
    this.setState({
      visibleSuccess: true,
    });
  };


  handleCancel = () => {
    this.setState({
      visibleSuccess: false,
    });
  };

  render() {
    const {
      form: { getFieldDecorator }
    } = this.props;

    let numberClassName = ''; //style.numberItem;
    /* if (isFirefox || isMobile) {
      itemClassName = style.mozNameItem;
      numberClassName = style.mozNumberItem;
    }*/

    let { loading } = this.state;

    return(
      <section className={style.menu}>
        <Form>
          <FormItem>
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: <br />
                },
                {
                  validator: (rule: any, value: any, callback: any) => {
                    let newVal = typeof value == 'undefined' ? value : formatName(value);
                    if (newVal != value) {
                      console.log([newVal, value])
                      this.props.form.setFieldsValue({
                        name: newVal
                      });
                    }
                    callback();
                  }
                }
              ]
            })(<Input placeholder={'Имя'} onChange={() => null} />)}
          </FormItem>
          <FormItem
            className={!this.state.focusNumber ? numberClassName : ''}
            validateStatus={this.state.validateNumberStatus}
            label={this.state.numberLabel ? 'Номер мобильного телефона *' : ' '}
          >
            {getFieldDecorator('number', {
              rules: [
                {
                  required: true,
                  message: <br />
                }
              ]
            })(
              <InputNumber
                placeholder={!this.state.numberLabel ? 'Номер мобильного телефона *' : ' '}
                max={9999999999}
                formatter={formatNumber}
                parser={parseNumber}
                onChange={this.handleNumberChange}
                onFocus={() => {
                  if (!this.props.form.getFieldValue('number')) {
                    this.props.form.setFieldsValue({
                      number: ' '
                    });
                  }
                  this.setState({
                    focusNumber: true
                  });
                }}
                onBlur={() => {
                  console.log(this.props.form.getFieldValue('number'));
                  if (this.props.form.getFieldValue('number') == ' ') {
                    this.props.form.setFieldsValue({
                      number: ''
                    });
                    this.setState({
                      focusNumber: false
                    });
                  }
                  if (!this.props.form.getFieldValue('number')) {
                    this.setState({
                      focusNumber: false
                    });
                  }
                }}
              />
            )}
          </FormItem>
          <Button
            className={classNames(style.submitButton, this.state.loading ? ' disabled' : '')}
            onClick={this.handleSubmit}
            styleName={'old'}>
            {loading ? <Icon type="loading" /> : 'ОТПРАВИТЬ'}
          </Button>
        </Form>
        <Modal
          visible={this.state.visibleSuccess}
          onOk={() => {
            this.handleCancel();
            this.props.onCancel();
          }}
          onCancel={() => {
            this.handleCancel();
            this.props.onCancel();
          }}
          className={style.modalSuccessForm}
          destroyOnClose={true}
        >
          <Success closeForm={() => {
            this.handleCancel();
            this.props.onCancel();
          }} />
        </Modal>
      </section>
    );
  }
}

export const WrappedCustomSimpleForm = Form.create()(SimpleForm);
