/*import { createAction } from 'redux-actions';
import { UserModel } from 'app/models';*/

export enum Type {
  ADD_USER = 'ADD_USER',
  AUTH_USER = 'AUTH_USER',
  EDIT_USER = 'EDIT_USER',
  DELETE_USER = 'DELETE_USER',
  COMPLETE_USER = 'COMPLETE_USER',
  COMPLETE_ALL = 'COMPLETE_ALL',
  CLEAR_COMPLETED = 'CLEAR_COMPLETED'
}

// types


export interface addTodo {
  type: typeof Type.ADD_USER
  payload: any
}

export interface authUser {
  type: typeof Type.AUTH_USER
  payload: Boolean
}

// actions

export const addTodo = function sendMessage(auth: Boolean): authUser {
  return {
    type: Type.AUTH_USER,
    payload: auth
  }
};
//export const authUser = createAction<PartialPick<UserModel, 'auth'>>(Type.AUTH_USER);
export const authUser = function sendMessage(auth: Boolean): authUser {
  return {
    type: Type.AUTH_USER,
    payload: auth
  }
};
export const editTodo = function sendMessage(auth: Boolean): authUser {
  return {
    type: Type.AUTH_USER,
    payload: auth
  }
};
export const deleteTodo = function sendMessage(auth: Boolean): authUser {
  return {
    type: Type.AUTH_USER,
    payload: auth
  }
};
export const completeTodo = function sendMessage(auth: Boolean): authUser {
  return {
    type: Type.AUTH_USER,
    payload: auth
  }
};

export const completeAll = function sendMessage(auth: Boolean): authUser {
  return {
    type: Type.AUTH_USER,
    payload: auth
  }
};
export const clearCompleted = function sendMessage(auth: Boolean): authUser {
  return {
    type: Type.AUTH_USER,
    payload: auth
  }
};


