import * as React from 'react';

import style from './style.module.css';
import {WrappedCustomFriendForm as FriendForm} from "../../components/FriendForm";
import * as currencyFormatter from "currency-formatter";
import fileIcon from "../../../assets/img/fileIcon.png";
import classNames from 'classnames'



export interface ActionFriend  {
  [key: string]: any
  visibleSendForm: any;
}


export class ActionFriend extends React.Component<ActionFriend, any> {
  static defaultProps: Partial<ActionFriend> = {
  };

   render() {
    return (
        <section className={style.action}>
            <article>Акция! <br/>Приведи друга - получи <span className={style.cashback}>4000 {currencyFormatter.findCurrency('RUB').symbol}</span></article>
            <article>
                <FriendForm />
                <div className={classNames(style.formRow, style.rules)}>
                    <img src={fileIcon} alt="" />
                    <a
                        href={'/files/Правила%20проведения%20акции%20приведи%20друга.pdf'}
                        target="_blank"
                        rel="noopener noreferrer" className={style.agreeText}>
                        <span>&nbsp;&nbsp;Правила проведения маркетинговой рекламной акции "Приведи друга"</span>
                    </a>
                </div>
            </article>
        </section>
    );
  }
}
