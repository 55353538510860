import * as React from 'react';
import { Modal } from 'antd';

import { Button } from 'app/components/Button';

import style from './style.module.css';

export interface About {
    visibleSendForm: any;
}

export class About extends React.Component<About, any> {
  static defaultProps: Partial<About> = {
    visibleSendForm: false
  };

  constructor(props: About, context?: any) {
    super(props, context);
    this.state = {
      visible: false,
        slider: ''
    };
  }

  componentDidMount(): void {
      import("app/components/Slider2")
          .then(({ SliderComponent }) => {
              console.log('load Map');
              this.setState({
                  slider: SliderComponent
              })
          })
          .catch(err => {
              console.log(err)
              // Handle failure
          });
  }

    showModal = () => {
    this.setState({
      visible: true
    });
  };

  handleOk = () => {
    this.setState({
      visible: false
    });
  };

  render() {
      let Slider = this.state.slider || (() => <div/>);
    return (
      <div className={style.about}>
        <h1>Кто мы?</h1>
        <p>
          Creditcar — это современная компания, которая Вам предложит займ быстро и безопасно.
          Честно и просто. Альтернатива, при которой транспортное средство остается в собственности,
          так же в пользовании своего владельца. Вы оставляете только ПТС, дополнительные документы
          не требуются.
        </p>
        <br />
        <h1>Кaк мы работаем?</h1>
        <p>
          Мы работаем строго в соответствии с законодательством Российской Федерации. Максимально
          прозрачно и честно. Рассматриваем заявки с любой кредитной историей, разрабатываем
          индивидуальный подход к каждому клиенту. Вам не потребуется подтверждать свои доходы,
          искать поручителей. Мы не обзваниваем Ваших коллег по работе, партнеров по бизнесу,
          родственников и друзей. Мы понимаем, что финансовая ситуация сугубо личное дело.{' '}
          <span onClick={this.showModal}>
            Мы готовы заключить сделку в любой день недели, без выходных, с возможностью досрочного
            погашения<span>*</span>.
          </span>
          За досрочное закрытие договора не предусмотрены штрафы, и производится перерасчет суммы
          исходя из фактического срока пользования займом.
        </p>
        <Slider />
        <br />
        <h1>Какие у нас ценности?</h1>
        <h2>Клиент</h2>
        <p>Ценим нашего клиентам. Поэтому наши сотрудники:</p>
        <p>
          Всегда помогают выходить из трудных ситуаций, а не грубо «выбивают долги»;
          <br />
          С пониманием относятся к неидеальной кредитной истории или альтернативной бухгалтерии;
          <br />
          Всегда пытаются помочь клиенту и понять его ситуацию — делают это каждый раз, когда это
          возможно.
        </p>
        <h2>Наши услуги</h2>
        <ul>
          <li>Продукт для клиентов.</li>
          <br />
          <li>
            Мы создали понятную линейку программ займов с учетом потребностей наших клиентов, чтобы
            сделать процесс принятия решение быстрым и не отнимать у Вас лишнего времени.
          </li>
        </ul>
        <br />
        <p>
          С 2013 года мы являемся членами саморегулируемой организации «Микрофинансирование и
          Развитие» (СРО «МиР»), в которую входят ведущие участники российского микрофинансового
          рынка. Миссия СРО «МиР»: Содействовать развитию рынка микрофинансирования в России с целью
          повышения доступности финансовых услуг и защиты прав потребителей.
        </p>
        <Modal
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleOk}
          className={style.infoModal}
        >
          <section className={style.info}>
            <p>Заключение сделки в субботу и воскресение возможно по предварительной записи.</p>
            <div onClick={this.handleOk}>
              <Button styleName={'default'}>ОК</Button>
            </div>
          </section>
        </Modal>
      </div>
    );
  }
}
